export function formattedPrice(price, priceFormatter) {
	if (price.c) {
		var pr = (price["prefix"]) ? price["prefix"] : ""
		pr = pr.endsWith(" ") ? pr : (pr + " ")

		const am = priceFormatter ? priceFormatter.format(price["amount"] || "") : (price["amount"] || "")

		return pr + price.c + " " + am
	} else {
		var pr = (price["prefix"]) ? price["prefix"] : ""
		pr = pr.endsWith(" ") ? pr : (pr + " ")

		var suff = (price["suffix"]) ? price["suffix"] : ""
		suff = suff.startsWith(" ") ? suff : (" " + suff)

		const am = priceFormatter ? priceFormatter.format(price["amount"] || "") : (price["amount"] || "")

		return pr + am + suff;
	}
}