import React, { Component, useState } from 'react'
import Fuse from 'fuse.js';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Collapsible from 'react-collapsible';
import { withTranslation } from "react-i18next";
import { config } from '../Constants';
import { Helmet } from 'react-helmet';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { InView } from 'react-intersection-observer';
import { ReactComponent as InfoSVG } from '../data/icons/info.svg';
import { formattedPrice } from '../utils/misc'
import ClearIcon from '@mui/icons-material/Clear'
import qs from "qs";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import './MenuPage.css';
import { v4 as uuidv4 } from 'uuid';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import OrderWidget from '../orders/utils/Orders';
import OrdersPageTranslated from '../orders/Orders';

const onLaptop = (window.innerWidth >= 1281);

function nItemsOpenOrder() {
	const openOrder = JSON.parse(window.localStorage.getItem("openOrder"))

	if (openOrder && openOrder.items) return openOrder.items.reduce((total, ele) => total + ele.quantity, 0)
	return 0
}

class MenuPage extends Component {

	constructor(props) {
		super(props);

		this.tableN = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).table;

		this.state = {
			selectedLanguage: "",
			menuData: undefined,
			menuId: props.defaultMenuId,
			metadata: props.metadata,
			showOrders: props.ordersOpen,
			timeoutId: null
		};

		if (props.metadata.priceFormat) {
			this.priceFormatter = new Intl.NumberFormat(props.metadata.priceFormat);
		}


		window.addEventListener("message", (event) => {
			if (event.origin.startsWith("https://admin.takeaseat.io") || event.origin.startsWith("https://takeaseat.io") || event.origin.startsWith("http://localhost") || event.origin.endsWith(".tas.ink")) {
				if (event.data.nature === "metadata") this.setState(prevState => { return { ...prevState, metadata: { ...prevState.metadata, ...event.data.content } } })
				if (event.data.nature === "menuData") this.setState({ menuData: event.data.content })
			} else {
				return;
			}
		});

		this.loadData = this.loadMenu.bind(this);
		this.handleLanguageChange = this.handleLanguageChange.bind(this);
		this.getMostAppropriateLanguage = this.getMostAppropriateLanguage.bind(this);
		this.toggleOrderView = this.toggleOrderView.bind(this)
	}

	toggleOrderView() {
		this.setState({ showOrders: !this.state.showOrders })
	}

	componentDidMount() {
		if (!this.state.metadata.lp) {
			this.loadMenu(this.getMostAppropriateLanguage(), data => this.setState({ menuData: data }));
		}

		if (window.location.hash.length > 1) {
			this.hash = window.location.hash
			window.location.hash = ""
			scrollToName({ name: window.location.hash.substring(2) })
			setTimeout(() => {
				window.location.hash = this.hash
				scrollToName({ name: this.hash.substring(2) })
			}, 200) // TODO: replace this fix to scroll to proper section
		}
	}

	loadMenu(lang, callback) {
		fetch(`${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/menus/${this.state.menuId}/${lang}/menu.json`, {
			method: 'get',
		}).then(data => data.json())
			.then(data => callback(data))
	}

	getMostAppropriateLanguage() {
		var qrcodeLang = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).lang;

		var lang = "";
		if (this.state.selectedLanguage !== "") {
			lang = this.state.selectedLanguage;
		} else if (qrcodeLang) {
			lang = qrcodeLang
		} else if ("language" in this.props.location) {
			lang = this.props.location.language;
		} else if ("language" in navigator && navigator.language !== "") {
			lang = navigator.language.substr(0, 2);
		} else {
			lang = "en";
		}

		if (!this.state.metadata.menus[this.state.menuId].supportedLanguages.some(sl => sl.code === lang)) {
			lang = this.state.metadata.menus[this.state.menuId].supportedLanguages[0].code;
		}
		return lang;
	}

	handleLanguageChange(lang) {
		this.setState(
			{ selectedLanguage: lang },
			() => this.loadMenu(
				this.getMostAppropriateLanguage(),
				data => {
					this.setState({ menuData: data, menuLang: lang });
				})
		);
	}

	render() {
		const { t, i18n } = this.props;

		const mLKey = (() => {
			if (this.state.metadata.logo && this.state.metadata.logo.src) return this.state.metadata.logo.src + (this.state.menuLang || "") + (this.state.metadata.priceFormat || "")
			return "metadata-logo" + (this.state.menuLang || "") + (this.state.metadata.priceFormat || "")
		})()

		if (this.state.showOrders) return <OrdersPageTranslated restaurantId={this.props.restaurantId} toggleOrderView={this.toggleOrderView} table={this.tableN} priceFormatter={this.priceFormatter} />

		const logoUrl = (this.state.metadata.logo && this.state.metadata.logo.src) ? (
			this.state.metadata.logo.src.length < 200 ? `${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/${this.state.metadata.logo.src}` : this.state.metadata.logo.src
		) : null

		if (this.state.metadata.menus[this.state.menuId].lp && !this.state.selectedLanguage) {
			return <>
				<div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", minHeight: "100%" }}>
					{logoUrl && <div className='logo' style={{
						width: "12rem",
						height: "12rem",
						marginBottom: "3rem",
						background: `url("${logoUrl}") center center / contain no-repeat`
					}}></div>}
					<div>
						<div>{t("choose-lg")}</div>
						{
							this.state.metadata.menus[this.state.menuId].supportedLanguages.map(lang => <div
								key={`lang-${lang.code}`}
								className="lg-selector"
								onClick={() => {
									this.handleLanguageChange(lang.code)
									i18n.changeLanguage(lang.code);
								}}>
								<img
									alt={lang.code}
									src={`/static/media/icons/lang/${lang.code}.svg`}
									width="32"
									height="32"
									style={{ marginRight: "0.5rem", objectFit: "cover", objectPosition: "center center", borderRadius: "50%" }} />
								<span><b>{lang.name}</b></span>
							</div>
							)
						}
					</div>
				</div>
			</>
		}

		if (this.state.menuData === undefined) {
			return <div className={"splash-screen"}>
				<div className='bg'></div>
				<div className='logo' style={{
					background: (logoUrl ? `url("${logoUrl}") center center / contain no-repeat` : "")
				}}></div>
			</div>
		}

		if (this.state.menuData === null) {
			return <div className="menu-page">
				{this.theme}
			</div >
		}

		return <div className={"menu-page template-3"}>
			<MenuList {...this.props}
				key={mLKey}
				sections={this.state.menuData}
				metadata={this.state.metadata}
				logoUrl={logoUrl}
				supportedLanguages={this.state.metadata.menus[this.state.menuId].supportedLanguages}
				language={this.getMostAppropriateLanguage()}
				onLanguageChange={this.handleLanguageChange}
				toggleOrderView={this.toggleOrderView}
				menuId={this.state.menuId} />
			{
				(!this.props.metadata.ordering && (this.state.metadata.include_watermark === undefined || this.state.metadata.include_watermark)) && <div
					className={"watermark" + (onLaptop ? " lg-screen" : "")}
					style={((this.props.metadata.fb ? this.props.metadata.fb.length > 0 : this.props.metadata.enableFeedback) && this.state.menuData.length > 0) ? { marginTop: "0" } : {}}>
					<a href="https://www.takeaseat.io" target="_blank">Created with www.takeaseat.io</a>
				</div>
			}
		</div >
	}
}

function UnderConstruction({ supportedLanguages, onLanguageChange, i18n, t, language }) {
	return <div className="under-construction">
		<div>{t('empty.0')}.</div>
		<br />
		{(supportedLanguages.length > 1) && <div>
			<div>{t('empty.1')}</div>
			<br />
			<div className="available-languages">{
				supportedLanguages.map(lang => lang["code"] === language ? <></> : <div
					key={`construct-lang-${lang['code']}`}
					className="available-language clickable"
					onClick={() => {
						onLanguageChange(lang.code);
						i18n.changeLanguage(lang.code);
					}}
				>
					<img
						alt={lang.code}
						src={`/static/media/icons/lang/${lang.code}.svg`}
						width="32"
						height="32"
						style={{ cursor: "pointer", marginRight: "0.5rem", marginBottom: "0.5rem", objectFit: "cover", objectPosition: "center center", borderRadius: "50%" }} />
				</div>
				)
			}</div>
		</div>}
	</div>
}

function ListLabels({ labels, isSet, toggle, childkey }) {
	return <div className={"labels"}>
		{labels.map((label, i) => (
			<div className={"label "} key={`${childkey}-${i}`}> {/* clickable  onClick={() => toggle(label)}*/}
				{label}{i < (labels.length - 1) && ','}
			</div>
		))}
	</div>
}

function ListIcons({ icons, isSet, toggle, childkey }) {
	return <div className="icons">
		{icons.map((icon, i) => (
			<img src={`${config.url.PUBLIC}/icons/${icon}`} className='icon clickable' width="24" height="24" key={`${childkey}-${i}`} onClick={() => toggle(icon)} />
		))}
	</div>
}

function Filters({ allLabels, isSet, toggle }) {
	return <div className={"filters"}>
		{allLabels.map((label, i) => (
			<div key={`filter-l-${i}`} className={"filter" + (isSet(label) ? " selected" : "")}
				onClick={() => toggle(label)} >
				{label.endsWith('.svg') ?
					<img src={`${config.url.PUBLIC}/icons/${label}`} className='icon clickable' width="24" height="24" />
					: <div >{label}</div>}
			</div>
		))}
	</div>
}

function atLeastOneOption(options) {
	var result = false;
	options.forEach((option) => {
		result = result || option.prefix || option.amount || option.suffix
	})
	return result
}

const LazyCarrousel = ({ images, restaurantId, item, size }) => {
	const [imageInView, setImageInView] = useState(0)

	return <div style={{ position: "relative", display: "flex" }}>
		<div className="carrousel-content" style={images.length === 1 ? { justifyContent: "center", paddingInline: "0" } : {}}>
			{images.map((_, i) => <LazyPicture
				key={`${item.id}-images-${i}-${images.length}`}
				size={size}
				nImages={images.length}
				visible={i < imageInView + 3}
				src={images[i].length < 200 ? `${config.url.PUBLIC}/restaurants/${restaurantId}/images/${images[i]}` : images[i]}
				alt={item.name}
				inViewCallback={() => setImageInView(i)} />)}
		</div>
	</div>
}

const LazyPicture = ({ src, alt, visible, size, inViewCallback, nImages }) => {
	const [load, setLoad] = useState(false)
	const [fullscreen, setFullscreen] = useState(false)
	const [w2HRatio, setW2HRatio] = useState(2)


	return <InView
		rootMargin={"0px 10px 750px 10px"}
		delay={0}
		style={{ display: (visible ? "" : "none") }}
		className={"lazy-image-container " + (size || "")}
		as="div"
		onClick={() => setFullscreen(prev => !prev)}
		threshold={0}
		triggerOnce={false}
		onChange={(inView, entry) => {
			if (inView) setLoad(inView)
			if (entry.isIntersecting) inViewCallback()
		}}>
		{load ? <img
			src={`${encodeURI(src)}`}
			className={"lazy-image " + (size || "") + (w2HRatio > 1 ? " landscape" : " portrait")}
			alt={alt}
			style={(w2HRatio <= 1 || (nImages && nImages > 1)) ? { width: `${10 * w2HRatio}rem`, marginRight: "0.5rem" } : {}}
			onLoad={(e) => setW2HRatio(e.target.naturalWidth / e.target.naturalHeight)}
		/> : <div className="lazy-image-placeholder"><div>Loading...</div></div>}
		{fullscreen && <div className='fullscreen'>
			<img
				src={`${encodeURI(src)}`}
				className={"lazy-image"}
				alt={alt} />
		</div>}
	</InView>
}

const Item = React.memo(function Item({ item, restaurantId, filterIsSet, toggleFilter, t, orderCallback, toggleOrderView, priceFormatter }) {
	if (item.link) return (
		<li name={item.id || ""} style={{ marginBottom: "1.25rem", cursor: "pointer" }}>
			<div className={"item "} onClick={() => window.location = item.link} style={{ padding: "0.5rem" }}>
				<div style={{ width: "100%" }}>
					<div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
						<div>
							<div className={"name"}>
								<div className="item-name" style={{ position: "relative" }}>
									{item.name}
								</div>
							</div>
							{item.description &&
								<div>
									<div style={{ position: "relative" }}>
										<div className={"description"}>{item.description}</div>
									</div>
								</div>
							}
						</div>
						{item.link && <div>
							<span><ArrowForwardIosIcon /></span>
						</div>}
					</div>
				</div>
			</div>
		</li>
	);

	// TODO: remove react memo?
	var prices = [];
	item.price.forEach((p, i) => prices.push(<div key={`${item.id}-price-${i}`}>{formattedPrice(p, priceFormatter)}</div>))

	const images = item.images || []
	// const images = [...(item.images || []), ...(item.images || []), ...(item.images || [])]

	return (
		<li name={item.id || ""} style={{ marginBottom: "1.25rem" }}>
			<div className={"item " + (orderCallback ? "w-order" : "")} style={{ padding: "0.5rem" }}>
				{item.hl && <div className="rainbow-container" >
					<div className="rainbow"></div>
				</div >}
				<div style={{ width: "calc(100% - 8.5rem)", marginBottom: "0", position: "relative", display: "flex", flexDirection: "column", justifyContent: "space-between" }} onClick={() => orderCallback ? orderCallback(item) : null}>
					<div className={"name"}>
						<div className="item-name" style={{ position: "relative" }}>
							{item.name}
						</div>
						<ListLabels childkey={item.id + "-labels"} labels={item.labels} isSet={filterIsSet} toggle={toggleFilter} />
						{item.icons?.length && <ListIcons childkey={item.id + "-icons"} icons={item.icons || []} isSet={filterIsSet} toggle={toggleFilter} />}
					</div>
					{item.description &&
						<div>
							<div style={{ position: "relative" }}>
								<div className={"description"}>{item.description}</div>
							</div>
						</div>
					}
					<div style={{ display: "flex", justifyContent: "space-between", marginTop: "0.5rem" }}>
						{(item.price.length > 0) && <div className={"price"}>{prices[0]}</div>}
						{/* {(atLeastOneOption(item.options) || item.co?.length > 0) && <div>View all options</div>} */}
						{(("notes" in item) && (item.notes !== "")) && <div style={{ position: "relative", margin: "0.5rem 0.25rem 0 0.25rem" }} onClick={() => orderCallback ? orderCallback(item) : null}>
							<div className={"notes"}>{item.notes}</div>
						</div>}
						{orderCallback && <div className="order-button" onClick={() => orderCallback(item)}>
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" width="23" height={20}><path d="M253.3 35.1c6.1-11.8 1.5-26.3-10.2-32.4s-26.3-1.5-32.4 10.2L117.6 192H32c-17.7 0-32 14.3-32 32s14.3 32 32 32L83.9 463.5C91 492 116.6 512 146 512H430c29.4 0 55-20 62.1-48.5L544 256c17.7 0 32-14.3 32-32s-14.3-32-32-32H458.4L365.3 12.9C359.2 1.2 344.7-3.4 332.9 2.7s-16.3 20.6-10.2 32.4L404.3 192H171.7L253.3 35.1zM192 304v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V304c0-8.8 7.2-16 16-16s16 7.2 16 16zm96-16c8.8 0 16 7.2 16 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V304c0-8.8 7.2-16 16-16zm128 16v96c0 8.8-7.2 16-16 16s-16-7.2-16-16V304c0-8.8 7.2-16 16-16s16 7.2 16 16z" /></svg>
						</div>}
					</div>
				</div>
				{(images.length > 0) && <div style={{ marginTop: "0.5rem" }}>
					<LazyCarrousel item={item} restaurantId={restaurantId} images={images} orderCallback={orderCallback} toggleOrderView={toggleOrderView} />
				</div>}
				{/* {atLeastOneOption(item.options) && <div style={{ position: "relative", margin: "0.5rem 0.25rem 0 0.25rem" }} onClick={() => orderCallback ? orderCallback(item) : null}>
					<div style={{ display: "flex", marginTop: "0.5rem" }}>
						<div style={{ fontWeight: "bold" }}>{item['options-label'] || t('options')}:</div>
						<ul className={"options"}>
							{item.options.map((option, i) =>
								<li key={`${item.id}-option-${i}`}>{formattedPrice(option, priceFormatter)}</li>
							)}
						</ul>
					</div>
				</div>} */}
				{/* {(item.co?.length > 0) && <div style={{ position: "relative", margin: "0.5rem 0.25rem 0 0.25rem" }} onClick={() => orderCallback ? orderCallback(item) : null}>
					<div style={{ marginTop: "0.5rem", marginBottom: "0.5rem" }}>
						<div style={{ fontWeight: "bold" }}>{t('customizations.title')}</div>
						<ul className={"options"}>
							{item.co?.map((customization, co) => <li key={`${item.id}-co-${co}`} className="customization">{customization.options.map((option, o) => {
								return <>
									{(o > 0) && <span> or </span>}
									<span key={`${item.id}-co-${co}-${o}`}>{option.label || ""}</span>
								</>
							})}</li>
							)}
						</ul>
					</div>
				</div>} */}
			</div>
		</li >
	);
})

function isEmptyItem(item) {
	return (
		item.name === "" &&
		item.description === "" &&
		item.price.length === 0 &&
		item.labels.length === 0 &&
		item.options.length === 0 &&
		item.notes === ""
	)
}

function scrollToName({ name }) {
	try {
		const ele = document.getElementsByName(name)
		if (ele[0]) ele[0].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
	} catch (e) { }
}

function Subsection({ subsection, section, restaurantId, t, filterIsSet, toggleFilter, orderCallback, toggleOrderView, defaultCollapsed, priceFormatter }) {
	const images = subsection.images || []

	return (
		<li>
			<Collapsible
				trigger={
					<div className="title clickable">
						<div style={{ display: "flex", margin: "auto", position: "relative" }}>
							{(subsection.name) ? <>
								<h3>{subsection.name}</h3>
								<div className="section-collapser"><ExpandLessIcon /></div>
							</> : <div><div className="section-collapser"><ExpandLessIcon /></div></div>}
						</div>
						<a id={'s' + section.id + "-" + subsection.id} className="sanchor"></a>
						<a id={section.id + "-" + subsection.id} className="anchor"></a>
					</div>
				}
				open={!defaultCollapsed || !subsection.name}>
				{(images.length > 0) && <>
					<LazyCarrousel item={subsection} restaurantId={restaurantId} images={images} size={"small"} toggleOrderView={toggleOrderView} />
					<br />
				</>}
				{subsection.description ? (<div className={"section-description"}>{subsection.description}</div>) : null}
				<ul className={"items"}>
					{subsection.items.map((item, index) => (
						(!isEmptyItem(item) && (!item.hide)) && <Item priceFormatter={priceFormatter} key={item.id} t={t} item={item} restaurantId={restaurantId} filterIsSet={filterIsSet} toggleFilter={toggleFilter} orderCallback={orderCallback} toggleOrderView={toggleOrderView} />
					))}
				</ul>
				{subsection.footnote ? (<div className={"footnote"}>
					<div><InfoSVG name="custom-svg" height="0.8em" width="0.8em" /> </div>
					<div>{subsection.footnote}</div>
				</div>) : null}
			</Collapsible>
		</li>
	)
}


function Section({ i, template, section, t, restaurantId, filterIsSet, toggleFilter, orderCallback, toggleOrderView, defaultCollapsed, priceFormatter }) {
	const images = section.images || []

	return <div>
		<div className="title clickable">
			<div style={{ display: "flex", margin: "auto", position: "relative", minWidth: "1px", minHeight: "1px" }}>
				{(i > 0 || template !== 2) && (
					(section.name) ? <>
						<h2>{section.name}</h2>
						<div className="section-collapser"><ExpandLessIcon /></div>
					</> : <div style={{ margin: "2rem 0" }}><div className="section-collapser"><ExpandLessIcon /></div></div>
				)}
				<a id={'s' + section.id} className="sanchor" style={(i === 0 && template === 2) ? { top: "calc(-56px - 6.5rem)" } : {}}></a>
			</div>
		</div>
		<a id={section.id} className="anchor"></a>
		{/* {(images.length > 0) && <>
			<LazyCarrousel item={section} restaurantId={restaurantId} images={images} size={"small"} toggleOrderView={toggleOrderView} />
			<br />
		</>} */}
		{section.description ? (<div className={"section-description"}>{section.description}</div>) : null}
		<ul className={"items"}>
			{section.items.map((item, index) => (
				(!isEmptyItem(item) && (!item.hide)) && <Item priceFormatter={priceFormatter} key={item.id} t={t} item={item} restaurantId={restaurantId} filterIsSet={filterIsSet} toggleFilter={toggleFilter} orderCallback={orderCallback} toggleOrderView={toggleOrderView} />
			))}
		</ul>
		<ul className={"subsections"}>
			{section.subsections.map(subsection => (
				(!subsection.hide) && <Subsection priceFormatter={priceFormatter} defaultCollapsed={defaultCollapsed} key={subsection.id} t={t} subsection={subsection} section={section} restaurantId={restaurantId} filterIsSet={filterIsSet} toggleFilter={toggleFilter} orderCallback={orderCallback} toggleOrderView={toggleOrderView} />
			))}
		</ul>
		{section.footnote ? (<div className={"footnote"}>
			<div><InfoSVG name="custom-svg" height="0.8em" width="0.8em" /></div>
			<div> {section.footnote}</div>
		</div>) : null}
	</div>
};

const ListSections = ({ template, sections, t, restaurantId, toggleFilter, filterIsSet, orderCallback, toggleOrderView, defaultCollapsed, priceFormatter, forceOpenSection }) => (
	<div className={"sections"}>
		{sections.map((section, i) => (
			(!section.hide) && <div key={`sec-${section.id}-${forceOpenSection}`}>
				<Section
					i={i}
					template={template}
					defaultCollapsed={defaultCollapsed && forceOpenSection !== section.id}
					section={section}
					t={t}
					restaurantId={restaurantId}
					toggleFilter={toggleFilter}
					filterIsSet={filterIsSet}
					orderCallback={orderCallback}
					priceFormatter={priceFormatter}
					toggleOrderView={toggleOrderView} />

			</div>
		))}
	</div>
)

class MenuList extends Component {
	constructor(props) {
		super(props);

		this.state = {
			selectedFilters: Object(),
			searchKey: '',
			showFilterBar: false,
			drawerState: false,
			showLogo: true,
			nItemsOpenOrder: nItemsOpenOrder(),
			itemSelected: null,
			showHighlight: true,
			selectedSection: props.sections[0]?.id || null,
			currentOrderId: uuidv4(),
			// openOrder: JSON.parse(window.localStorage.getItem("openOrder")) || { items: [] }
		}

		if (props.metadata.priceFormat) {
			this.priceFormatter = new Intl.NumberFormat(props.metadata.priceFormat);
		}
		this.allAnchors = this.props.sections.filter(s => !s.hide).map(s => `s${s.id}`)

		this.filterIsSet = this.filterIsSet.bind(this);
		this.setFilter = this.setFilter.bind(this);
		this.toggleFilter = this.toggleFilter.bind(this);
		this.searchAndFilterItems = this.searchAndFilterItems.bind(this);
		this.gatherFuseResultItems = this.gatherFuseResultItems.bind(this);
		this.filterItems = this.filterItems.bind(this);
		this.handleScroll = this.handleScroll.bind(this)
	}

	handleScroll = event => {
		if (!this.state.searchKey) {

			var bestAnchor = this.allAnchors.length > 0 ? this.allAnchors[0] : ""

			for (const anchor of this.allAnchors) {

				const elem = document.getElementById(anchor);

				if (!elem) return

				const bounderies = elem.getBoundingClientRect();

				if (bounderies.top >= 0 && bounderies.top < ((window.innerHeight || document.documentElement.clientHeight) / 4) && bounderies.bottom <= (window.innerHeight || document.documentElement.clientHeight)) {
					bestAnchor = anchor
					break
				} else if (bounderies.top < 0) {
					bestAnchor = anchor
				}
			}

			if (this.timer !== null) {
				clearTimeout(this.timer);
			}
			this.timer = setTimeout(function () {
				scrollToName({ name: bestAnchor.substring(1) })
			}, 100);

			if (this.state.selectedSection != bestAnchor.substring(1)) {
				this.setState({ selectedSection: bestAnchor.substring(1) }, () => {
					scrollToName({ name: bestAnchor.substring(1) })
				})
			}
		}
	};

	componentDidMount() {
		const rootDiv = document.querySelector('#root'); // store in a variable so we can reference the element in multiple locations

		if (this.props.metadata.template === 2) rootDiv.addEventListener('scroll', this.handleScroll);
	}

	filterIsSet(label) {
		return label in this.state.selectedFilters
	}

	setFilter(label) {
		this.setState(prevState => {
			let sf = { ...prevState.selectedFilters };
			sf[label] = true;
			return { selectedFilters: sf, showFilterBar: true };
		})
	}

	toggleFilterBar = (e) => {
		this.setState(prevState => {
			return { showFilterBar: !prevState.showFilterBar }
		})
	}

	toggleFilter(label) {
		if (this.filterIsSet(label)) {
			this.setState(prevState => {
				let sf = { ...prevState.selectedFilters };
				delete sf[label];
				return { selectedFilters: sf };
			})
			if (window.sendEvent) window.sendEvent("Remove filter", label, this.props.restaurantId)
		} else {
			this.setFilter(label);
			if (window.sendEvent) window.sendEvent("Set filter", label, this.props.restaurantId)
		}
	}

	searchAndFilterItems(searchKey, filters) {
		const fuse = new Fuse(this.props.sections, {
			keys: [
				'items.name',
				'items.description',
				'subsections.items.name',
				'subsections.items.description'
			],
			includeMatches: true,
			ignoreLocation: true,
			threshold: 0.1,
			shouldSort: false
		});

		var sec = (searchKey === '') ? this.props.sections : this.gatherFuseResultItems(fuse.search(searchKey));
		return this.filterItems(sec, filters);
	}

	gatherFuseResultItems(fuseResult) {
		var sections = []
		for (var i = 0; i < fuseResult.length; i++) {
			var section = fuseResult[i].item;
			var matches = fuseResult[i].matches;
			var itemIndexes = new Set();
			var subsectionItemIndexes = new Set();

			for (let j = 0; j < matches.length; j++) {
				if (matches[j].key.startsWith("items")) {
					itemIndexes.add(matches[j].refIndex);
				} else {
					subsectionItemIndexes.add(matches[j].refIndex);
				}
			}

			let items = [];
			for (let j = 0; j < section.items.length; j++) {
				if (itemIndexes.has(j)) {
					items.push(section.items[j]);
				}
			}

			var subsections = [];
			var subsectionItemIndex = 0;
			section.subsections.forEach(subsection => {
				let items = [];
				subsection.items.forEach(i => {
					if (subsectionItemIndexes.has(subsectionItemIndex)) {
						items.push(i);
					}
					subsectionItemIndex += 1;
				})

				if (items.length > 0) {
					subsections.push({
						id: subsection.id,
						name: subsection.name,
						description: section.description,
						footnote: section.footnote,
						items: items
					})
				}
			})

			sections.push({
				id: section.id,
				name: section.name,
				description: section.description,
				footnote: section.footnote,
				items: items,
				subsections: subsections
			});
		}

		return sections
	}

	filterItems(sections, filters) {
		if (Object.keys(filters).length === 0)
			return sections
		return sections.map(section => ({
			id: section.id,
			name: section.name,
			description: section.description,
			footnote: section.footnote,
			items: section.items.filter(item =>
				item.labels.some(label => (label in filters) && filters[label]) ||
				item.icons?.some(icon => (icon in filters) && filters[icon])
			),
			subsections: section.subsections.map(subsection => ({
				id: subsection.id,
				name: subsection.name,
				description: subsection.description,
				footnote: subsection.footnote,
				items: subsection.items.filter(item =>
					item.labels.some(label => (label in filters) && filters[label]) ||
					item.icons?.some(icon => (icon in filters) && filters[icon])
				),
			})).filter(subsection => subsection.items.length > 0)
		})).filter(section => section.items.length > 0 || section.subsections.length > 0);
	}

	render() {
		var allLabels = []
		var chefSelection = null

		this.props.sections.forEach(section => {
			section.items.forEach(item => {
				if (item.labels) allLabels = allLabels.concat(item.labels)
				if (item.icons) allLabels = allLabels.concat(item.icons)
				if (item.cs && !item.hide && !section.hide) chefSelection = {
					id: item.id,
					name: item.name,
					description: item.description,
					sectionId: section.id
				}
			})
			section.subsections.forEach(subsection => {
				subsection.items.forEach(item => {
					if (item.labels) allLabels = allLabels.concat(item.labels)
					if (item.icons) allLabels = allLabels.concat(item.icons)
					if (item.cs && !item.hide && !section.hide && !subsection.hide) chefSelection = {
						id: item.id,
						name: item.name,
						description: item.description,
						sectionId: section.id
					}
				})
			})
		})
		allLabels = Array.from(new Set(allLabels))
		allLabels.sort()


		const filteredSections = this.searchAndFilterItems(this.state.searchKey, this.state.selectedFilters);

		const { t, i18n } = this.props;

		return <div className={onLaptop ? "lg-screen" : ""}>
			<Helmet htmlAttributes={{ dir: ["fa", "ar", "he"].includes(this.props.language) ? "rtl" : "ltr" }}>
				<title>{this.props.metadata.name || this.props.metadata.alias || "Take a Seat"} | Menu</title>
				<meta name="description" content={`Menu of ${this.props.metadata.name || this.props.metadata.alias || this.props.restaurantId}`} />
				<meta property="og:type" content="website" />
				<meta property="og:title" content={`Menu of ${this.props.metadata.name || this.props.metadata.alias || this.props.restaurantId}`} />
				<meta property="og:description" content={`Online menu of ${this.props.metadata.name || this.props.metadata.alias || this.props.restaurantId}`} />
				<meta property="og:url" content={`${config.url.PUBLICPAGE}/${this.props.metadata.alias || this.props.restaurantId}/menu`} />
				<meta property="og:site_name" content={this.props.metadata.name || this.props.metadata.alias || this.props.restaurantId} />
				<link rel="icon" type="image/png" href={
					(this.props.metadata.logo && this.props.metadata.logo.src) ? `${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/${this.props.metadata.logo.src}`
						: "favicon.ico"
				} sizes="52x52" />
			</Helmet>
			<React.Fragment>
				<AppBar position="sticky" tabIndex="-1">
					<Toolbar>
						{!onLaptop && <MenuIcon onClick={() => this.setState({ drawerState: true })} />} {/** On laptop we show drawer */}
						<div style={{ display: "flex", marginLeft: "auto", alignItems: "center" }}>
							{this.props.metadata.ordering && <div onClick={this.props.toggleOrderView} style={{ marginLeft: "2rem", position: "relative" }}>
								<AccountCircleIcon style={{ fill: "currentcolor" }} />
								{/* {this.state.nItemsOpenOrder ? <BubbleNumber key={`bubble-${nItemsOpenOrder}`} number={this.state.nItemsOpenOrder} variant={"warning"} /> : null} */}
							</div>}
						</div>
					</Toolbar>
					<div className={"filters-appbar " + (this.state.showFilterBar ? "open" : "close")}>
						<Filters allLabels={allLabels} isSet={this.filterIsSet} toggle={this.toggleFilter} />
						<div><ClearIcon style={{ cursor: "pointer" }} onClick={() => this.setState({ showFilterBar: false, searchKey: '' })} /></div>
					</div>
				</AppBar>
			</React.Fragment>
			<React.Fragment key={'left'}>
				<Drawer
					className={onLaptop ? "lg-screen" : ""}
					disableEnforceFocus={true}
					id={'main-drawer'} anchor={'left'} open={this.state.drawerState || onLaptop} onClose={() => this.setState({ drawerState: false })}>
					{(this.state.showLogo && this.props.logoUrl) &&
						<img
							style={{ maxWidth: "16rem", margin: "2rem auto", marginBottom: "0", objectFit: "contain", objectPosition: "center center" }}
							width="126"
							height="126"
							alt={this.props.metadata.logo.alt}
							data-type="image"
							itemProp="image"
							src={this.props.logoUrl}
							onError={(e) => {
								this.setState({ showLogo: false })
							}} className="sidebar-logo" />
					}
					<ul className={"drawer-sections"}>
						{this.props.sections.map(section => ((section.hide) ? <></> : <>
							<h3 key={`drawer-${section.id}`}
								onClick={() => {
									this.setState({ drawerState: false, selectedSection: section.id },
										() => {
											window.location.hash = "" // Clean the hash to rescroll to the anchor
											window.location.hash = "#" + "s" + section.id
											setTimeout(() => {
												scrollToName({ name: section.id })
											}, 100)
										})
								}}>{section.name}
							</h3>
							{section.subsections.map((subsection, s) => (
								(subsection.hide) ? <></> : <>
									<h4 key={`drawer-${subsection.id}`} style={{}}>
										<a
											href={(this.props.metadata.template === 2) ? `#${section.id}-${subsection.id}` : `#s${section.id}-${subsection.id}`}
											onClick={() => this.setState({ drawerState: false, selectedSection: section.id })}>{subsection.name}</a>
									</h4>
								</>
							))}
						</>
						))}
					</ul>
					<div className="bottom-elements">
						{(this.props.metadata.menus[this.props.metadata.activeMenu].supportedLanguages.length > 1) && <div
							style={{ display: "flex", overflow: "auto" }}>
							{this.props.metadata.menus[this.props.metadata.activeMenu].supportedLanguages.map(lang => (
								<img
									onClick={() => {
										this.props.onLanguageChange(lang.code);
										i18n.changeLanguage(lang.code);
										this.setState({ drawerState: false });
									}}
									key={`img-${lang}`}
									alt={lang.code}
									src={`/static/media/icons/lang/${lang.code}.svg`}
									width="32"
									height="32"
									style={{ border: "1px solid rgba(0,0,0,0.1)", cursor: "pointer", marginRight: "0.5rem", marginBottom: "0.5rem", objectFit: "cover", objectPosition: "center center", borderRadius: "50%" }} />
							))}
						</div>}
					</div>
				</Drawer>
			</React.Fragment>
			<section>
				{(this.state.showLogo && this.props.logoUrl) &&
					<div style={{ textAlign: "center" }}>
						<img
							alt={this.props.metadata.logo.alt}
							style={{ objectFit: "contain", objectPosition: "center center" }}
							width="180"
							height="180"
							data-type="image"
							itemProp="image"
							src={this.props.logoUrl}
							onError={(e) => {
								this.setState({ showLogo: false })
							}} className="menu-logo" />
					</div>
				}
				{this.props.sections.length === 0 ?
					<UnderConstruction
						t={t}
						i18n={i18n}
						onLanguageChange={this.props.onLanguageChange}
						supportedLanguages={this.props.metadata.menus[this.props.metadata.activeMenu].supportedLanguages}
						language={this.props.language}
					/>
					: <>
						<div style={{
							backgroundImage: `url(${config.url.PUBLIC}/images/room.png)`,
							width: "100%",
							height: "10rem",
							backgroundSize: "cover",
						}}></div>
						{this.props.metadata.template === 2 && <div className='selector'>{
							this.props.sections.map((section, i) => {
								if (section.hide) return <></>
								return <div
									key={`sel-${i}`}
									name={section.id || "n"}
									className={this.state.selectedSection === section.id ? "selected" : ""}
									onClick={(e) => {
										this.setState({ selectedSection: section.id }, () => {
											window.location.hash = "" // Clean the hash to rescroll to the anchor
											window.location.hash = "#" + "s" + section.id
											scrollToName({ name: section.id })
										})
									}}><span>{section.name}</span></div>
							})
						}
						</div>}
						<ListSections
							template={this.props.metadata.template}
							sections={filteredSections}
							priceFormatter={this.priceFormatter}
							defaultCollapsed={this.props.metadata.c ? this.props.metadata.c : false}
							t={t}
							toggleFilter={this.toggleFilter}
							filterIsSet={this.filterIsSet}
							restaurantId={this.props.restaurantId}
							orderCallback={(this.props.metadata.ordering) ? ((item) => this.setState({ itemSelected: item, currentOrderId: uuidv4(), slideOpenWidget: true })).bind(this) : undefined}
							toggleOrderView={this.props.toggleOrderView}
							forceOpenSection={this.state.forceOpenSection} /> {/** TODO: remove toggle order view */}
					</>
				}
				{(chefSelection && this.state.showHighlight) && <div className='dish-highlight menu-page'>
					<section onClick={() => {
						this.setState({ forceOpenSection: chefSelection.sectionId, selectedSection: chefSelection.sectionId, showHighlight: false }, () => {
							try {
								const ele = document.getElementsByName(chefSelection.id)
								if (ele.length > 0) ele[0].scrollIntoView({ behavior: "auto", block: "center", inline: "nearest" })
							} catch (e) { }
						})
					}}>
						<div className="item">
							<div className='chef-choice'>{t('chef-selection')}</div>
							<div style={{ paddingBottom: "0.5rem" }}><b>{chefSelection.name || ""}</b></div>
							<div>{chefSelection.description || ""}</div>
						</div>
					</section>
					<div
						className='clear'
						onClick={() => this.setState({ showHighlight: false })}>
						<ClearIcon />
					</div>
				</div>}
				{this.props.metadata.ordering && <OrderWidget
					key={this.state.currentOrderId}
					open={this.state.openOrderWidget}
					// openOrder={this.state.openOrder}
					itemSelected={this.state.itemSelected}
					restaurantId={this.props.restaurantId}
					slideOpen={this.state.slideOpenWidget}
					priceFormatter={this.priceFormatter}
					// updateCurrentOrder={(newValue) => {
					// 	window.localStorage.setItem("openOrder", JSON.stringify(newValue))
					// 	this.setState({
					// 		currentOrderId: uuidv4(),
					// 		openOrder: newValue,
					// 		openOrderWidget: false,
					// 		itemSelected: null,
					// 		nItemsOpenOrder: nItemsOpenOrder(),
					// 		slideOpenWidget: false
					// 	})
					// }}
					toggleOrderView={this.props.toggleOrderView}
				/>}
			</section>
		</div>
	}
}

const MenuPageTranslated = withTranslation('common')(MenuPage)

export default MenuPageTranslated
