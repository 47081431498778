import React, { useEffect, useState } from 'react'
import { config } from '../../Constants';
import { useTranslation } from "react-i18next"
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import './Orders.css'
import { formattedPrice } from '../../utils/misc'
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { NativeSelect } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
var { QRCodeSVG } = require('qrcode.react'); /** TODO: use other */

export function correctGst(amount, gst) {
    const gstMultiple = gst ? 1.0 + gst / 100 : 1.0

    return Math.ceil(100 * (gstMultiple * amount)) / 100
}

export function totalAmount(basketElements, coupons) {
    // Remove null, undefined items
    const cleanedBasketElements = basketElements.filter(n => Number.isInteger(n.price) && n.item && n.item.price.length >= (n.price + 1))

    // console.log(cleanedBasketElements)
    var couponDiscount = 0
    if (coupons) coupons.forEach(coupon => couponDiscount += parseFloat(coupon.amount || 0))

    if (cleanedBasketElements.length > 0) {

        var sprice = cleanedBasketElements.reduce((total, ele) => {
            return total + priceToFloat(ele.item.price[ele.price]["amount"]) * ele.quantity
        }, 0)

        var soptions = cleanedBasketElements.reduce((total, ele) => {
            if (ele.options != undefined) return total + ele.options.reduce((ototal, oele) => ototal + priceToFloat(ele.item.options[oele]["amount"]) * ele.quantity, 0)
            return total
        }, 0)

        return Math.max(0, sprice + soptions - couponDiscount)
    }

    return 0
}

const deviceStorage = window.localStorage;

export function ValidationQRcode({ orderId }) {
    const { t } = useTranslation('ordering')

    return <div>
        <QRCodeSVG
            includeMargin={false}
            level="L"
            renderAs="svg"
            value={`https://guest.takeaseat.io/order-validation/${orderId}`} />
        <div style={{ fontSize: "80%", textAlign: "center" }}>{t('scan')}</div>
    </div>
}

let re = /([0-9.,']+)/g;

export function priceToFloat(priceString) {
    try {
        const matches = `${priceString}`.match(re) // Necessary if priceString is already number  TODO: improve.
        const price = (matches) ? matches[0].replace(/[ ]/ig, "").replace(/[,]/ig, ".") : 0
        return parseFloat(price)
    } catch (e) {
        return NaN
    }
}

export function updateLocalStoreOrderStatus(orderId, newStatus) {
    var openOrders = JSON.parse(deviceStorage.getItem("openOrders")) || []
    const i = openOrders.findIndex((openOrder) => openOrder.id === orderId)

    if (i == -1) return //TODO: this should not happen

    openOrders[i]['status'] = newStatus
    deviceStorage.setItem("openOrders", JSON.stringify(openOrders))
}

export function closeOrder(orderId) {
    var openOrders = JSON.parse(deviceStorage.getItem("openOrders")) || []
    var closedOrders = JSON.parse(deviceStorage.getItem("closedOrders")) || []

    const i = openOrders.findIndex((openOrder) => openOrder.id === orderId)
    if (i == -1) return //TODO: this should not happen

    openOrders[i]["status"] = "closed"
    closedOrders.push(openOrders[i])
    deviceStorage.setItem("closedOrders", JSON.stringify(closedOrders))

    openOrders.splice(i, 1)
    deviceStorage.setItem("openOrders", JSON.stringify(openOrders))
}

export function deleteOrder(orderId) {
    var closedOrders = JSON.parse(deviceStorage.getItem("closedOrders")) || []
    const i = closedOrders.findIndex((closedOrder) => closedOrder.id === orderId)

    if (i > -1) {
        closedOrders.splice(i, 1)
        deviceStorage.setItem("closedOrders", JSON.stringify(closedOrders))
    } else {
        var openOrders = JSON.parse(deviceStorage.getItem("openOrders")) || []
        const i = openOrders.findIndex((openOrder) => openOrder.id === orderId)

        if (i > -1) {
            openOrders.splice(i, 1)
            deviceStorage.setItem("openOrders", JSON.stringify(openOrders))
        }
    }
}

export function getOrderStatus({ orderId, callback }) {
    fetch(`${config.url.API}/order/status/${orderId}`, {
        method: 'get',
    }).then(data => data.json())
        .then(data => callback(data))
}

export async function placeOrder({ order, restaurantId }) {
    return fetch(`${config.url.API}/order/${restaurantId}`, {
        method: 'put',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(order)
    })
}

function ListLabels({ labels, isSet, toggle, childkey }) { //todo: put in utils
    return <div className={"labels"}>
        {labels.map((label, i) => (
            <div className={"label"} key={`${childkey}-${i}`}>
                {label}{i < (labels.length - 1) && ','}
            </div>
        ))}
    </div>
}

export default function OrderWidget({ itemSelected, updateCurrentOrder, open, slideOpen, toggleOrderView, restaurantId, priceFormatter }) {
    const [openWidget, setOpenWidget] = useState(open)
    const { t } = useTranslation('ordering');
    const [quantity, setQuantity] = useState(0)
    const [priceSelected, setPriceSelected] = useState(0)
    const [optionsSelected, setOptionsSelected] = useState([])
    const [customizationsSelected, setCustomizationsSelected] = useState(itemSelected?.co?.map(c => c.options[0]?.label))
    const [comment, setComment] = useState(null)
    const [orderElement, setOrderElement] = useState({
        item: itemSelected,
        quantity: 0,
        price: 0,
        options: [],
        customizations: [],  // TODO: Take value from user selection 
        comment: null
    })


    const [orderElementIndex, setOrderElementIndex] = useState(null)
    const [openOrder, setOpenOrder] = useState(JSON.parse(window.localStorage.getItem("openOrder")) || { items: [] })

    useEffect(() => {
        window.localStorage.setItem("openOrder", JSON.stringify(openOrder))
    }, [openOrder])

    useEffect(() => {
        var newOpenOrder = JSON.parse(JSON.stringify(openOrder))

        if (orderElementIndex !== null && quantity === 0) {
            newOpenOrder.items.splice(orderElementIndex, 1)
        }
        else if (orderElementIndex !== null && quantity > 0) {
            newOpenOrder.items[orderElementIndex] = {
                item: orderElement.item,
                quantity: quantity,
                price: priceSelected,
                options: optionsSelected,
                customizations: customizationsSelected,  // TODO: Take value from user selection 
                comment
            }
        }
        else if (quantity > 0) {
            newOpenOrder.items.push({
                item: orderElement.item,
                quantity: quantity,
                price: priceSelected,
                options: optionsSelected,
                customizations: customizationsSelected,  // TODO: Take value from user selection 
                comment
            })
            setOrderElementIndex(newOpenOrder.items.length - 1)
        }
        setOpenOrder(newOpenOrder)
    }, [quantity]) // TODO: support finish order
    // TODO: change also on update

    useEffect(() => {
        if (orderElementIndex == null) return

        var newOpenOrder = JSON.parse(JSON.stringify(openOrder))

        newOpenOrder.items[orderElementIndex].price = priceSelected
        newOpenOrder.items[orderElementIndex].options = optionsSelected
        newOpenOrder.items[orderElementIndex].customizations = customizationsSelected
        newOpenOrder.items[orderElementIndex].comment = comment

        setOpenOrder(newOpenOrder)
    }, [priceSelected, optionsSelected, customizationsSelected, comment])

    React.useEffect(() => {
        if (slideOpen) setTimeout(() => setOpenWidget(true), 10) // TODO Fix
    }, [])

    React.useEffect(() => {
        setQuantity(orderElement.quantity || 0)
        setPriceSelected(orderElement.price || 0)
        setOptionsSelected(orderElement.options || [])
        setCustomizationsSelected(orderElement.customizations.length > 0 ? orderElement.customizations : (orderElement.item?.co?.map(c => c.options[0]?.label) || []))
        setComment(orderElement.comment || null)
    }, [orderElement])

    return <>
        <div onClick={() => setOpenWidget(true)} className="go-to-order">{t('order-here')}</div>
        <div className={"order-widget-wrapper " + (openWidget ? "open" : "closed")}>
            <div className="order-widget">
                <div className="bg" onClick={() => setOpenWidget(false)}></div>
                <div className="handle" onClick={() => setOpenWidget((prev) => !prev)}></div>
                {orderElement.item && <div className="selected-item">
                    <div className="content">
                        <div className="top row">
                            <div style={{ fontSize: '125%', display: "flex", alignItems: "center" }}><b>{orderElement.item.name}</b></div>
                            <div className="price">
                                {orderElement.item.price && (
                                    orderElement.item.price.length === 1 ? <div>
                                        {formattedPrice(orderElement.item.price[0], priceFormatter)}
                                    </div> :
                                        <FormControl fullWidth>
                                            <NativeSelect
                                                id="demo-simple-select"
                                                value={priceSelected}
                                                size="small"
                                                variant="standard"
                                                inputProps={{ style: { paddingBlock: "0.25rem", fontWeight: "bold", color: "white" } }}
                                                onChange={(e) => {
                                                    setPriceSelected(parseInt(e.target.value))
                                                }}
                                            >
                                                {orderElement.item.price.map((pr, i) => <option style={{ color: "black" }} key={`price-${orderElement.item.id}-${i}`} value={`${i}`}>{formattedPrice(pr, priceFormatter)}</option>)}
                                            </NativeSelect>
                                        </FormControl>)}
                            </div>
                        </div>
                        {(orderElement.item?.labels && orderElement.item.labels.length > 0) && <div style={{ marginBottom: "1rem", paddingInline: "1rem" }}><ListLabels labels={orderElement.item.labels} childkey={`ordering-${orderElement.item?.id}`} /></div>}
                        <div style={{ marginBottom: "1rem", paddingInline: "1rem" }}>{orderElement.item.description}</div>
                        <div className="row bottom">
                            {(orderElement.item.options && orderElement.item.options.length > 0) && <div style={{ marginBottom: "1rem" }}>
                                <div style={{ marginBottom: "0.5rem" }}><b>{t('options')}</b></div>
                                <div className="item-options">
                                    {orderElement.item.options.map((option, i) => {
                                        const optionIndex = optionsSelected.findIndex((ele) => ele === i)
                                        const optionIsSelected = optionIndex > -1

                                        return <div
                                            style={{ display: "flex" }}
                                            key={`${orderElement.item.id}-option-${i}-${optionIsSelected}`}
                                            onClick={() => {
                                                if (optionIsSelected) {
                                                    optionsSelected.splice(optionIndex, 1)
                                                    setOptionsSelected([...optionsSelected])
                                                } else {
                                                    optionsSelected.push(i)
                                                    setOptionsSelected([...optionsSelected])
                                                }
                                            }}
                                        >
                                            {optionIsSelected ? <CheckBoxIcon sx={{ fill: "#c30010" }} /> : <CheckBoxOutlineBlankIcon />}
                                            <div style={{ marginLeft: "0.5rem" }}>{formattedPrice(option, priceFormatter)}</div>
                                        </div>
                                    })}
                                </div>
                            </div>}
                            {(orderElement.item.co?.length > 0) && <div style={{ marginBottom: "1rem" }}>
                                <div style={{ marginBottom: "0.5rem" }}><b>{orderElement.item.co.length > 1 ? "Customizations" : "Customization"}</b></div>
                                <div>
                                    {orderElement.item.co.map((customization, i) => {
                                        return <Select
                                            fullWidth
                                            key={customizationsSelected[i]}
                                            size="small"
                                            defaultValue={customizationsSelected[i]}
                                            value={customizationsSelected[i]}
                                            onChange={(e) => {
                                                customizationsSelected[i] = e.target.value
                                                setCustomizationsSelected(customizationsSelected => [...customizationsSelected])
                                            }}>
                                            {customization.options.map((option, o) => <MenuItem value={option["label"]}>{option.label}</MenuItem>)}
                                        </Select>
                                    })}
                                </div>
                            </div>}
                            {comment !== null ?
                                <textarea value={comment} placeholder="Type comment..." rows="3" style={{ width: "100%", fontFamily: "inherit", border: "1px solid rgba(0,0,0,0.1)", borderRadius: "4px", padding: "0.5rem" }} onChange={(e) => {
                                    setComment(e.target.value)
                                }} /> : <div style={{ display: "flex", marginBottom: "1.5rem" }}>
                                    <div style={{ display: "flex", alignItems: "center" }} onClick={() => setComment("")}>
                                        <b>{t('add-comment')}</b>&#160;
                                        <DriveFileRenameOutlineIcon />
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <div className="quantity">
                        {/* <div className='left'>
                            {(orderElementIndex !== null && quantity === 0) && <div className={"add-basket"}>
                                <span onClick={() => {
                                    openOrder.items.splice(orderElementIndex, 1)
                                    updateCurrentOrder(openOrder)
                                }}>{t('remove-from-basket')}</span>
                            </div>}
                            {(orderElementIndex !== null && quantity > 0) && <div className={"add-basket update"}>
                                <span onClick={() => {
                                    openOrder.items[orderElementIndex] = {
                                        item: orderElement.item,
                                        quantity: quantity,
                                        price: priceSelected,
                                        options: optionsSelected,
                                        customizations: customizationsSelected,  // TODO: Take value from user selection 
                                        comment
                                    }
                                    updateCurrentOrder(openOrder)
                                }}>{t('update-basket')}</span>
                            </div>}
                        </div> */}
                        <div className='mid'>
                            <div>
                                <div className="remove" style={quantity === 0 ? { background: "rgba(0,0,0,0.1)" } : {}} onClick={() => setQuantity((prev) => {
                                    if (prev > 0) return prev - 1
                                    return 0
                                })}><RemoveIcon /></div>
                                <div className="qy">{quantity}</div>
                                <div className="add" onClick={() => setQuantity((prev) => prev + 1)}><AddIcon /></div>
                            </div>
                        </div>
                        {/* <div className="right">
                            {(orderElementIndex === null) && <div className={"add-basket " + (quantity === 0 ? "deactivated" : "")}>
                                <span onClick={() => {
                                    if (quantity > 0) {
                                        openOrder.items.push({
                                            item: orderElement.item,
                                            quantity: quantity,
                                            price: priceSelected,
                                            options: optionsSelected,
                                            customizations: customizationsSelected,  // TODO: Take value from user selection 
                                            comment
                                        })
                                        updateCurrentOrder(openOrder)
                                    }
                                }}>{t('add-to-basket')}</span>
                            </div>}
                        </div> */}
                    </div>
                </div>}
                <div className="current-order-items">
                    {(openOrder.items.length === 0) ? <>
                        <div style={{ color: "grey", marginTop: "1rem", width: "100%", textAlign: "center" }}>{t('empty-basket')}</div>
                    </>
                        : <>
                            <div className="bubbles">
                                {openOrder.items.map((orderElement, i) => <div
                                    className="order-bubble"
                                    key={`current-order-item-${i}`}
                                    onClick={() => {
                                        if (orderElement.item == null) {
                                            openOrder.items.splice(i, 1)
                                            updateCurrentOrder(openOrder)
                                        } else {
                                            setOrderElement(orderElement)
                                            setOrderElementIndex(i)
                                        }
                                    }}>
                                    <div style={(orderElement.item.images && orderElement.item.images.length > 0) ? {
                                        backgroundImage: `url('${config.url.PUBLIC}/restaurants/${restaurantId}/images/${orderElement.item.images[0]}')`,
                                        backgroundSize: "cover"
                                    } : {}}>
                                        <span><b>{orderElement.item.name?.[0]}</b></span>
                                        <div className="selected-qty">{orderElement.quantity}</div>
                                    </div>
                                </div>)}
                            </div>
                            <div style={{ marginLeft: "auto" }}> {/** TODO: link */}
                                <div className="order-bubble" style={{ paddingRight: "0" }} >
                                    <div
                                        onClick={toggleOrderView}
                                        style={{ cursor: "pointer", minWidth: "7.5rem", background: "black", color: "white", width: "auto", borderRadius: "8px", padding: "0 1rem", position: "relative" }}>
                                        <b>{t('finish-order')}</b>
                                        <div style={{ position: "absolute", bottom: "-0.5rem", paddingLeft: "1rem", transform: "translate(-50%, 100%)", left: "50%", color: "black", whiteSpace: "nowrap", paddingRight: "1rem" }}>{t('total')}: {openOrder.items?.length > 0 ?
                                            (priceFormatter ? priceFormatter.format(totalAmount(openOrder.items)) : totalAmount(openOrder.items)) : 0}</div>
                                    </div>
                                </div>
                            </div>
                        </>}
                </div>
            </div>
        </div>
    </>
}
