export function bestLanguage(autoLanguage=true, selectedLanguage=null, supportedLanguages=["en"]) {
    if (supportedLanguages.includes(selectedLanguage)) return selectedLanguage; 
    if (autoLanguage & supportedLanguages.includes(browserLanguage())) return browserLanguage();
    return "en";
}

export function browserLanguage() {
    if ("language" in navigator && navigator.language !== "") {
        // The navigator.language returns locales including the country (en-US for example).
        // It's probably overkill to provide localization per country, so we are only interested
        // in the language part.
        return navigator.language.substring(0, 2);
    } else {
        return "en";
    }
}
