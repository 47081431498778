export const config = {
    url: {
        API: window._env_.API_URL,
        GUEST: window._env_.GUEST_URL,
        PUBLIC: window._env_.PUBLIC_URL,
        LANDING: window._env_.LANDING_URL,
        ADMIN: window._env_.ADMIN_URL,
        PUBLICPAGE: window._env_.WEBSITE_URL,
        BOOKING_API: window._env_.BOOKING_API_URL
    }
}
