import React, { useEffect, useState } from 'react';
import './App.css';
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { config } from './Constants';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import qs from "qs";
import WebsitePage from './website/App'
import MenuPage from './menu/MenuPage'

const OrderValidationPage = React.lazy(() => import('./orders/OrderValidation'));
const RegisterPage = React.lazy(() => import('./registration/RegisterPage'));
const BookingPage = React.lazy(() => import('./booking/BookingPage'));


window.sendEvent = function (event, subEvent, restaurantId) {
	const customerId = new Cookies().get('_tas_cid') || window._tas_id

	fetch(`${config.url.API}/analytics/event`, {
		method: 'post',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({ event, subEvent, restaurantId, customerId })
	})
}

// function customDomain() {
// 	if (config.url.PUBLICPAGE !== window.location.origin) {
// 		if (window.location.hostname.slice(-7) === 'tas.ink') return window.location.hostname.slice(0, -8)
// 		if (window.location.hostname.slice(-3) === "com") return window.location.hostname.slice(0, -4)
// 		return window.location.hostname.substring(5) // we remove 'site.' in front of top level domain TODO: remove this // TODO: remove
// 	}
// 	return null
// }

// function identifier() {
// 	if (window.location.hostname.slice(-7) === 'tas.ink') return window.location.hostname.slice(0, -8)
// 	if (window.location.hostname.slice(-3) === "com") return window.location.hostname.slice(0, -4)
// 	if (window.location.hostname.endsWith("localhost")) return window.location.hostname.split('.')[0]

// 	return window.location.hostname.substring(5) // we remove 'site.' in front of top level domain TODO: remove this // TODO: remove
// }

function resolveAlias({ alias }) {
	return fetch(`${config.url.PUBLIC}/restaurants/${alias}/alias.json`, {
		method: 'GET'
	})
		.then(res => {
			if (res.status === 404) {
				return alias
			} else {
				return res.json().then(data => {
					// alias.json contains {id: RESTAURANT_ID} pointing to the real ID if it was accessed through the alias URL.
					// If it does not contain the "id" field, then it means the URL was not an alias (it was already the real ID).
					return data.id || alias;
				})
			}
		})
		.catch(e => {
			console.error(e);
			return null
		});
}

function loadMetadata({ restaurantId }) {
	return fetch(`${config.url.PUBLIC}/restaurants/${restaurantId}/metadata.json`, {
		method: 'GET'
	})
		.then(res => res.json())
		.then(metadata => {
			// Has no active menu. This should not be the case if there is at least one menu.
			if (metadata.activeMenu === "" || metadata.menus[metadata.activeMenu] === undefined) {
				metadata.activeMenu = "default"
				metadata.menus["default"] = { "label": "Default", "supportedLanguages": [{ "name": "Anglais", "code": "en" }] }
			}

			// Have at least one supported language
			if (metadata.menus[metadata.activeMenu].supportedLanguages.length === 0) metadata.menus[metadata.activeMenu].supportedLanguages.push({ "code": "en", "name": "English" })
			return metadata
		})
		.catch(e => {
			console.error(e)
			return {}
		})
}

async function logAnalyticsEvent({ event }) {
	return fetch(`${config.url.API}/analytics/visit`, {
		method: 'post',
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		credentials: "include",
		body: JSON.stringify(event)
	});
}

const LandingRedirect = (props) => {
	// If the path contains the restaurant id instead of alias, we replace it by the alias
	var urlParts = window.location.pathname.split("/")
	if (props.metadata.alias && props.metadata.alias != urlParts[1]) {
		urlParts[1] = props.metadata.alias;
	}

	if (props.source === "direct") {
		return <React.Suspense fallback={<></>}>
			<WebsitePage {...props} {...props.metadata} />
		</React.Suspense>
	}

	var landingPath = "";
	if (props.metadata.landing === "contact-tracing") {
		landingPath = "register";
	} else if (props.metadata.landing === "menu") {
		landingPath = "menu";
	}

	// The restaurant is using its own domain. The redirection should not mention the restaurant name
	return <Redirect to={`/${landingPath}${props.location.search}`} />;
}

function getSource(props) {
	var context = qs.parse(props.location.search, { ignoreQueryPrefix: true }).context;
	var utm_medium = qs.parse(props.location.search, { ignoreQueryPrefix: true }).utm_medium;
	var source
	if (context == 'preview')
		source = 'preview';
	else if (context == 'landing-page')
		source = 'landing-page';
	else if (utm_medium == 'qrcode')
		source = 'qrcode';
	else
		source = 'direct';
	return source;
}

function getMenuId(props) {
	return qs.parse(props.location.search, { ignoreQueryPrefix: true }).menu;
}

function setCookie() {
	const cookies = new Cookies();
	var id = (cookies.get('_tas_cid') || window._tas_id) || uuidv4();
	window._tas_id = id
	const current = new Date();
	const nextYear = new Date();
	nextYear.setFullYear(current.getFullYear() + 1);
	cookies.set('_tas_cid', id, { path: '/', expires: nextYear });
}

function getPage() {
	if (window.location.pathname.endsWith("/menu") || window.location.pathname.endsWith("/orders")) return "menu"
	if (window.location.pathname.endsWith("/register")) return "contact_tracing"
	// TODO: add more
	return "homepage"
}

function Main() {
	const [restaurantId, setRestaurantId] = useState()
	const [metadata, setMenuMetadata] = useState()

	const curId = "hotelciputra"

	useEffect(() => {
		if (metadata && metadata.id) {
			logAnalyticsEvent({
				event: {
					customerId: (new Cookies().get('_tas_cid')),
					restaurantId: metadata.id,
					page: getPage(),
					source: getSource(window),
					useragent: navigator.userAgent
				}
			})
		}
	}, [metadata])

	if (!restaurantId) {
		// First step is to resolve the alias to know the restaurant id
		resolveAlias({ alias: curId }).then(restaurantId => setRestaurantId(restaurantId))
		return <></> // TODO: We can not render the restaurant if we do not know what it is
	}

	if (!metadata) {
		loadMetadata({ restaurantId }).then(metadata => setMenuMetadata(metadata))
		return <></>
	}

	// We should now have all the necessary information to render the component
	setCookie()

	return <BrowserRouter>
		<Switch>
			<Route exact
				path="/"
				render={(props) => {
					const menuId = getMenuId(props)
					const defaultMenuId = (menuId && menuId in metadata.menus) ? menuId : metadata.activeMenu
					// TODO: remove getMenuId
					return <LandingRedirect {...props} metadata={metadata} defaultMenuId={defaultMenuId} logo={metadata.logo} source={getSource(props)} restaurantId={restaurantId} />
				}} />
			<Route exact
				path="/order-validation/:orderId"
				render={(props) => {
					return <React.Suspense fallback={<></>}>
						<OrderValidationPage orderId={props.match.params.orderId} />
					</React.Suspense>
				}} />
			<Route exact
				path="/orders"
				render={(props) => {
					const menuId = getMenuId(props)
					const defaultMenuId = (menuId && menuId in metadata.menus) ? menuId : metadata.activeMenu
					return <>
						<link rel="stylesheet" type="text/css" href={`${config.url.PUBLIC}/restaurants/${restaurantId}/themes/theme.css`} />
						<MenuPage {...props} metadata={metadata} defaultMenuId={defaultMenuId} source={getSource(props)} page='menu' restaurantId={restaurantId} showOrders={true} />
					</>
				}} />
			<Route exact
				path="/register"
				render={(props) => {
					return <React.Suspense fallback={<></>}>
						<RegisterPage {...props} metadata={metadata} menuId={getMenuId(props)} source={getSource(props)} page='contact_tracing' restaurantId={restaurantId} />
					</React.Suspense>
				}} />
			<Route exact
				path="/menu"
				render={(props) => {
					const menuId = getMenuId(props)
					const defaultMenuId = (menuId && menuId in metadata.menus) ? menuId : metadata.activeMenu

					return <>
						<link rel="stylesheet" type="text/css" href={`${config.url.PUBLIC}/restaurants/${restaurantId}/themes/theme.css`} />
						<MenuPage {...props} metadata={metadata} defaultMenuId={defaultMenuId} source={getSource(props)} page='menu' restaurantId={restaurantId} />
					</>
				}} />
			<Route exact
				path="/booking"
				render={(props) => {
					return <React.Suspense fallback={<></>}>
						<BookingPage {...props} metadata={metadata} restaurantId={restaurantId} />
					</React.Suspense>
				}} />
			<Route exact
				path="/reservation"
				render={(props) => {
					return <React.Suspense fallback={<></>}>
						<BookingPage {...props} metadata={metadata} restaurantId={restaurantId} />
					</React.Suspense>
				}} />
		</Switch>
	</BrowserRouter>
};

export default Main;
