import React, { useEffect, useState } from 'react'
import './App.css'
import './index.css'
import './sections.css'
import { config } from '../Constants'
import { Helmet } from 'react-helmet'
import qs from "qs";
import { Circle, CircleOutlined, Clear, Facebook, Instagram, Twitter } from '@mui/icons-material';


function scrollToName({ name }) {
  try {
    const ele = document.getElementsByName(name)
    if (ele[0]) ele[0].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
  } catch (e) { }
}

const PromotionCard = ({ promotion, restaurantId }) => {
  const [showModal, setShowModal] = React.useState(false)

  return <div name={promotion.id} style={{
    minWidth: "100%",
    height: "30vh",
    background: `url("${config.url.PUBLIC}/restaurants/${restaurantId}/images/${encodeURIComponent(promotion.image)}") center center / cover`,
    borderRadius: "16px",
    position: "relative",
    scrollSnapAlign: "start"
  }}>
    {showModal ? <div style={{ zIndex: "1", position: "fixed", inset: 0, background: "rgba(0,0,0,0.2)", display: "flex", padding: "1rem" }}>
      <div style={{ background: "white", borderRadius: "8px", maxWidth: "100%", width: "45rem", position: "relative", padding: "1rem", margin: "0 auto", overflow: "scroll" }}>
        <img src={`${config.url.PUBLIC}/restaurants/${restaurantId}/images/${encodeURIComponent(promotion.image)}`} alt="promotion" width="300" height={300}
          style={{
            width: "100%", height: "auto", borderRadius: "8px"
          }} />
        <h2>{promotion.title}</h2>
        <div style={{ whiteSpace: "pre-line", textAlign: "left" }}>{promotion.description}</div>
        {promotion.link.url && <div>
          <a href={promotion.link.url} target="_blank" rel="nofollow"><div
            style={{
              padding: "1rem",
              borderRadius: "8px",
              fontWeight: "bold",
              border: "2px solid",
              marginBlock: "1rem",
            }}>{promotion.link.text || promotion.link.url}</div></a>
        </div>}
        <div style={{ position: "absolute", top: 0, right: "0", padding: "0.5rem", background: "white", borderRadius: "50%", display: "flex" }}>
          <Clear onClick={(e) => setShowModal(false)} />
        </div>
      </div>
    </div> : <div onClick={() => setShowModal(true)} style={{ position: "absolute", inset: "0" }}></div>}
  </div>
}

function getMostAppropriateLanguage({ location, selectedLanguage, metadata, defaultMenuId }) {
  var qrcodeLang = qs.parse(location.search, { ignoreQueryPrefix: true }).lang;

  var lang = "";
  if (selectedLanguage !== "") {
    lang = selectedLanguage;
  } else if (qrcodeLang) {
    lang = qrcodeLang
  } else if ("language" in location) {
    lang = location.language;
  } else if ("language" in navigator && navigator.language !== "") {
    lang = navigator.language.substr(0, 2);
  } else {
    lang = "en";
  }

  if (!metadata.menus[defaultMenuId].supportedLanguages.some(sl => sl.code === lang)) {
    lang = metadata.menus[defaultMenuId].supportedLanguages[0].code;
  }
  return lang;
}

function App({ location, restaurantId, defaultMenuId, metadata, logo }) {
  const [menuData, setMenuData] = useState([])
  const [promotionInView, setPromotionInView] = useState(null)
  const [promotionInterval, setPoromotionInterval] = useState(null)

  const restaurantIdAlias = 'hotelciputra'
  const theme = <link rel="stylesheet" type="text/css" href={`${config.url.PUBLIC}/restaurants/${restaurantId}/public-page/design.css`} />

  useEffect(() => {
    fetch(`${config.url.PUBLIC}/restaurants/${restaurantId}/menus/${defaultMenuId}/${getMostAppropriateLanguage({ location, selectedLanguage: "", metadata, defaultMenuId })}/menu.json`, {
      method: 'get',
    }).then(data => data.json())
      .then(setMenuData)
  }, [])

  useEffect(() => {
    if (promotionInView) scrollToName({ name: promotionInView.id })
  }, [promotionInView])

  useEffect(() => {
    if (metadata.promotions?.length > 0) setPromotionInView(metadata.promotions[0])
  }, [metadata])

  useEffect(() => {
    if (promotionInterval) clearInterval(promotionInterval)

    if (!(metadata.promotions?.length > 0)) return

    setPoromotionInterval(setInterval(() => {
      const currIndex = metadata.promotions.findIndex(ele => ele.id === promotionInView?.id)

      if (currIndex < 0 || currIndex === (metadata.promotions.length - 1)) {
        setPromotionInView(metadata.promotions[0])
      } else {
        setPromotionInView(metadata.promotions[currIndex + 1])
      }
    }, 2000))
  }, [promotionInView])

  return (
    <div className="App">
      <Helmet>
        <title>{restaurantIdAlias || restaurantId || "Public page"}</title>
        <meta name="description" content={`Website of ${restaurantIdAlias}`} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={`Website of ${restaurantIdAlias}`} />
        <meta property="og:description" content={`Website of ${restaurantIdAlias}`} />
        <meta property="og:url" content={`${config.url.PUBLICPAGE}/${restaurantIdAlias}`} />
        <meta property="og:site_name" content={restaurantIdAlias} />
        <link rel="icon" type="image/png" href={
          (logo && logo.src) ? `${config.url.PUBLIC}/restaurants/${restaurantId}/${logo.src}`
            : "favicon.ico"
        } sizes="52x52" />
      </Helmet>
      <header className="">{theme}</header>
      <div className="sections">
        {(logo && logo.src) && <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <div style={{ width: "18rem", minHeight: "7rem", background: `url("${config.url.PUBLIC}/restaurants/${restaurantId}/${logo.src}") center left / contain no-repeat`, margin: "4rem 0" }}></div>
        </div>}
        {/** Promotions */}
        {(metadata.promotions && metadata.promotions.length > 0) && <div style={{ padding: "0 2rem", textAlign: "center" }}>
          <div style={{ fontSize: "125%", fontWeight: "bold", marginBottom: "0.5rem" }}>Flash promotion</div>
          <div className='promotion-cards'>
            {metadata.promotions.map(promotion => <PromotionCard promotion={promotion} restaurantId={restaurantId} />)}
          </div>
          {metadata.promotions.length > 1 && <div style={{ display: "flex", gap: "0.5rem", justifyContent: "center", marginTop: "1rem" }}>{metadata.promotions.map(promotion => {
            if (promotionInView?.id === promotion.id) return <div><Circle style={{ fontSize: "80%" }} /></div>
            else return <div onClick={() => setPromotionInView(promotion)}><CircleOutlined style={{ fontSize: "80%" }} /></div>
          })}
          </div>}
        </div>}
        <div style={{ padding: "0 2rem", marginTop: "4rem" }}>
          <div style={{ textAlign: "center", fontSize: "125%", fontWeight: "bold", marginBlock: "2rem" }}>Food & Beverage Menu</div>
          <div className="menu-container">
            {menuData.map(
              section => {
                return <>
                  <a href={`/menu#s${section.id}`} style={{ textAlign: "center", marginBottom: "1rem", color: "inherit", fontWeight: "bold" }}>
                    <div style={{ boxShadow: "0px 0px 0px 2px grey", borderRadius: "16px", marginBottom: "0.5rem" }}>
                      <div style={{
                        backgroundImage: section.images && section.images.length ? `url('${config.url.PUBLIC}/restaurants/${restaurantId}/images/${section.images[0]}')` : "url('/images/food-menu-icon.png')",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        width: "10rem",
                        minHeight: "6rem"
                      }}></div>
                    </div>
                    <div>{section.name}</div>
                  </a>
                </>
              }
            )}
          </div>
        </div>
      </div>
      <div className='footer'>
        <div style={{ padding: "3rem 2rem 2rem", maxWidth: "64rem", width: "100%", background: "#f04e30" }}> {/** TODO: refactor */}
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <div><a href="https://www.hotelciputra.com" rel="me"><b>Hotel Ciputra</b></a></div>
              <div><a href="https://www.hotelciputra.com" rel="me"><b>www.hotelciputra.com</b></a></div>
            </div>
            <div style={{ display: "flex", gap: "1rem" }}>
              <a href="https://www.instagram.com/hotelciputrajkt/" rel="me"><Instagram /></a>
              <a href="https://www.facebook.com/HotelCiputraJakarta" rel="me"><Facebook /></a>
              <a href="https://twitter.com/hotelciputrajkt" rel="me"><Twitter /></a>
            </div>
          </div>
          <div style={{ marginTop: "2rem" }}>Powered by <a href="https://takeaseat.io" rel="follow"><b>Take a Seat</b></a></div>
        </div>
      </div>
    </div >
  );
}

export default App;
