import React, { Component, useState, useEffect } from 'react'
import { withTranslation, useTranslation } from "react-i18next";
import './Orders.css'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Menu from "@mui/material/Menu";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ClearIcon from '@mui/icons-material/Clear'
import LoadingButton from '@mui/lab/LoadingButton';
import { formattedPrice } from '../utils/misc'
import { config } from '../Constants';
import { correctGst, totalAmount, placeOrder, getOrderStatus, updateLocalStoreOrderStatus, ValidationQRcode, closeOrder, deleteOrder, priceToFloat } from '../orders/utils/Orders'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { TaSTimePicker } from '../utils/DateTimeUtils'
import TextField from '@mui/material/TextField';
import CheckIcon from '@mui/icons-material/Check';
import ShareLocationIcon from '@mui/icons-material/ShareLocation';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Box } from '@mui/system';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import StarIcon from '@mui/icons-material/Star';
import SyncIcon from '@mui/icons-material/Sync';

export function contrastingColorBw(color) {
    try {
        color = (color.charAt(0) === '#') ? color.substring(1, 7) : color;

        var r = parseInt(color.slice(0, 2), 16),
            g = parseInt(color.slice(2, 4), 16),
            b = parseInt(color.slice(4, 6), 16);

        // http://stackoverflow.com/a/3943023/112731
        return (r * 0.299 + g * 0.587 + b * 0.114) > 180
            ? '#000000'
            : '#FFFFFF';
    }
    catch (e) {
        return "#000"
    }
}

const deviceStorage = window.localStorage;

const payment_methods = {
    'payment_staff': 'Payment with staff',
    'payment_cash': 'Cash on delivery',
    'payment_transfer': 'Bank transfer',
    'coupon': 'Coupon',
    'card_on_delivery': 'Card on delivery', // TODO: Don't sent the translation. Translate on the admin side.
    'cash_on_delivery': 'Cash on delivery', // TODO: same
    'room_charge': 'Room charge', // TODO: same
}

function getOrdersMetadata({ restaurantId, successCallback }) {
    fetch(`${config.url.PUBLIC}/restaurants/${restaurantId}/orders-metadata.json`, {
        method: 'get',
    }).then(resp => {
        if (resp.status === 200) resp.json().then(
            data => successCallback(data)
        )
        // ELSE: TODO 
    })
}

//TODO move to utils
function updateItemQuantity(itemIndex, newQuantity) {
    var openOrder = JSON.parse(deviceStorage.getItem("openOrder"))

    if (newQuantity < 1) {
        openOrder.items.splice(itemIndex, 1)
    } else {
        openOrder.items[itemIndex].quantity = newQuantity
    }

    deviceStorage.setItem("openOrder", JSON.stringify(openOrder))
    return openOrder
}

function updateClosedOrder({ orderId, attributes }) {
    var closedOrders = JSON.parse(deviceStorage.getItem("closedOrders")) || []

    const i = closedOrders.findIndex(order => order.id == orderId)

    if (i < 0) return

    Object.keys(attributes).map(key => {
        closedOrders[i][key] = attributes[key]
    })

    deviceStorage.setItem("closedOrders", JSON.stringify(closedOrders))
    return true
}

function TimeSelector({ label, time_slots, callback }) {

    const d = new Date(new Date().getTime() + 15 * 60 * 1000)
    const minValue = `${("0" + d.getHours()).slice(-2)}:${("0" + d.getMinutes()).slice(-2)}`
    const slots = time_slots.map(slot => [slot["from"], slot["to"]])

    return <>
        <TaSTimePicker minValue={minValue} validSlots={slots} callback={callback} />
        {(time_slots.length > 0) && <div style={{ fontSize: "80%", marginTop: "0.25rem" }}>{label} {time_slots.map(slot => `From ${slot['from']} To ${slot['to']}`).join(" and ")}</div>}
    </>
}

function Receipt({ order, autoRefresh, a_gst, gst, priceFormatter, review, qrcode }) {
    const [orderStatus, setOrderStatus] = useState(order.status)
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isDeleted, setIsDeleted] = React.useState(false); // Hack to update UI when clicking on delete
    const [ratingExperience, setRatingExperience] = React.useState(order.feedback?.ratingExperience || 0)
    const [ratingFood, setRatingFood] = React.useState(order.feedback?.ratingFood || 0)
    const [ratingDelivery, setRatingDelivery] = React.useState(order.feedback?.ratingDelivery || 0)
    const [comment, setComment] = React.useState(order.feedback?.comment || "")
    const [sendingFeedback, setSendingFeedback] = React.useState(false)
    const [feedbackUpdated, setFeedbackUpdated] = React.useState(false)
    const [firstRender, setFirstRender] = React.useState(true)
    const { t } = useTranslation('ordering')

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const open = Boolean(anchorEl);

    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        setFirstRender(false)
    }, [])

    useEffect(() => {
        if (!firstRender) setFeedbackUpdated(true)
    }, [ratingDelivery, ratingExperience, ratingFood, comment])

    useEffect(() => {
        // We update the order status on first render only
        if (autoRefresh) {
            setLoading(true)
            handleClose()
            getOrderStatus({
                orderId: order.id, callback: ((data) => {
                    if (data.status) {
                        if (data.status === "closed" || data.status === "rejected") {
                            setOrderStatus(data.status)
                            closeOrder(order.id)
                        } else {
                            const newStatus = (data.status === 'waiting') ? 'Sent to the kitchen' :
                                (data.status === "accepted" ? "In progress" : data.status) // TODO: translate
                            updateLocalStoreOrderStatus(order.id, newStatus)
                            setOrderStatus(newStatus)
                        }
                    } else {
                        // Order not found in backend. Move it to closed orders
                        closeOrder(order.id)
                    }
                    setTimeout(() => setLoading(false), 500)
                })
            })
        }
    }, [false]);

    // TODO : write callback to update local storage
    //  TODO: better callback 
    const totalAmount = (() => {
        var couponDiscount = 0
        if (order.coupons) order.coupons.forEach(coupon => couponDiscount += parseFloat(coupon.amount || 0))

        if (order.items.length > 0) {
            var sprice = order.items.map(item => {
                if (item.price) return priceToFloat(item.price["amount"]) * item.quantity
                return 0
            }).reduce((total, value) => total + value, 0)
            var soptions = order.items.map(item => {
                if (item.options) return item.options.map(option => priceToFloat(option["amount"]) * item.quantity).reduce((total, value) => total + value, 0)
                return 0
            }).reduce((total, value) => total + value, 0)

            return Math.max(0, sprice + soptions - couponDiscount)
        }
    })()

    return <div className="receipt" style={{ display: (isDeleted ? "none" : "") }}>
        {loading && <div style={{ position: "absolute", inset: 0, fontWeight: "bold", background: "rgba(255, 255, 255, 0.9)", zIndex: 999, display: "flex", alignItems: "center", justifyContent: "center" }}>
            Loading...
        </div>}
        <div className="receipt-content">
            <h2 style={{ position: "relative", display: "flex", justifyContent: "space-between" }}>
                #{order.number}
                {(orderStatus === "closed" || orderStatus === "rejected") ? <>
                    <IconButton
                        aria-label="more"
                        onClick={handleClick}
                        aria-haspopup="true"
                        aria-controls="long-menu"
                    >
                        <MoreVertIcon />
                    </IconButton>
                    <Menu
                        anchorEl={anchorEl}
                        keepMounted onClose={handleClose}
                        open={open}>
                        <MenuItem
                            onClick={() => {
                                setLoading(true)
                                handleClose()
                                getOrderStatus({
                                    orderId: order.id, callback: ((data) => {
                                        if (data.status) {
                                            const newStatus = (data.status === 'waiting') ? 'Sent to the kitchen' : data.status
                                            updateLocalStoreOrderStatus(order.id, newStatus)
                                            setOrderStatus(newStatus)
                                        }
                                        setTimeout(() => setLoading(false), 500)
                                    })
                                })
                            }}>
                            {t('refresh')}
                        </MenuItem>
                        <MenuItem onClick={() => {
                            handleClose()
                            deleteOrder(order.id)
                            setIsDeleted(true)
                        }}> {t('delete')} </MenuItem>
                    </Menu>
                </> : <>
                    <div
                        onClick={() => {
                            setLoading(true)
                            handleClose()
                            getOrderStatus({
                                orderId: order.id, callback: ((data) => {
                                    if (data.status) {
                                        const newStatus = (data.status === 'waiting') ? 'Sent to the kitchen' : data.status
                                        updateLocalStoreOrderStatus(order.id, newStatus)
                                        setOrderStatus(newStatus)
                                    }
                                    setTimeout(() => setLoading(false), 500)
                                })
                            })
                        }}>
                        <SyncIcon />
                    </div>
                </>}
            </h2>
            <div>
                <div className={'order-status ' + (orderStatus || "")}><b>{t('status')} {orderStatus}</b></div>
                {order.pickup_time && <div style={{ margin: "0.5rem 0" }}>Pickup time: {order.pickup_time}</div>}
                <div style={{ margin: "1rem 0", borderBottom: "1px solid lightgrey" }}>
                    {order.items.map((item, i) => <>
                        <div className="order-item" key={`${order.id}-${i}`}>
                            <div><b>{item.name}</b></div>
                            {item.price && <div>Price: {formattedPrice(item.price, priceFormatter)}</div>} {/* TODO: display price nicely */}
                            {(item.options?.length > 0) && <>{
                                item.options.length === 1 ?
                                    <div>{t('option')}: {formattedPrice(item.options[0], priceFormatter)}</div> :
                                    <div>{t('options')}: {item.options.map(option => formattedPrice(option, priceFormatter)).join(' | ')}</div>}
                            </>}
                            {(item.customizations?.length > 0) && <>{
                                item.customizations.length === 1 ?
                                    <div>{t('customization')}: {item.customizations.join(',')}</div> :
                                    <div>{t('customizations')}: {item.customizations.join(',')}</div>}
                            </>}
                            {item.quantity && <div>{t('quantity')}: {item.quantity}</div>}
                            {item.comment && <div>{t('comment')}: {item.comment}</div>}
                        </div>
                        {(i < order.items.length - 1) && <div style={{ width: "100%", borderBottom: "1px solid rgb(220, 220, 220)", position: "absolute", left: "0" }}></div>}
                    </>
                    )}
                    {order.coupon_hid && <p>{t('payment-coupon')}: {order.coupon_hid}</p>}
                    {(order.coupons_hid && order.coupons_hid.length > 0) && <p>{t('payment-coupon')}: <b>{order.coupons_hid.map(c => `${c} `)}</b> applied</p>}
                    {a_gst && <div style={{ marginBottom: "0.5rem" }}>{t('add-gst')}: {gst}%</div>}
                    {(!a_gst && gst > 0) && <div>{t('includes-gst')}: {gst}% </div>}
                    <div style={{ marginBottom: "0.5rem" }}><b>{t('total')}: {priceFormatter ? priceFormatter.format(correctGst(totalAmount, a_gst ? gst : 0)) : correctGst(totalAmount, a_gst ? gst : 0)} </b></div>
                </div>
            </div>
            {qrcode && <div style={{ display: "flex", justifyContent: "center" }}>
                <ValidationQRcode orderId={order.id} />
            </div>}
            {review && <div>
                <h2>{t('give-rating')}</h2>
                <div style={{ margin: "1rem 0 0.5rem 0" }}><b>{t('rating-experience')}</b></div>
                <div className='stars'>
                    {[1, 2, 3, 4, 5].map(i => <div key={`star-${i}`} onClick={() => setRatingExperience(i)}>
                        {ratingExperience >= i ? <StarIcon /> : <StarOutlineIcon />}
                    </div>)}
                </div>
                <div style={{ margin: "1rem 0 0.5rem 0" }}><b>{t('rating-food')}</b></div>
                <div className='stars'>
                    {[1, 2, 3, 4, 5].map(i => <div key={`star-${i}`} onClick={() => setRatingFood(i)}>
                        {ratingFood >= i ? <StarIcon /> : <StarOutlineIcon />}
                    </div>)}
                </div>
                <div style={{ margin: "1rem 0 0.5rem 0" }}><b>{t('rating-delivery')}</b></div>
                <div className='stars'>
                    {[1, 2, 3, 4, 5].map(i => <div key={`star-${i}`} onClick={() => setRatingDelivery(i)}>
                        {ratingDelivery >= i ? <StarIcon /> : <StarOutlineIcon />}
                    </div>)}
                </div>
                <div style={{ margin: "1rem 0 0.5rem 0" }}><b>{t('rating-comment')}</b></div>
                <TextField maxRows={5} multiline={true} rows={3} size="small" fullWidth value={comment} onChange={(e) => setComment(e.target.value)} />
                {feedbackUpdated && <LoadingButton loading={sendingFeedback} onClick={() => {
                    if (!sendingFeedback) {
                        setSendingFeedback(true)
                        fetch(`${config.url.API}/order/feedback/${order.id}`, {
                            method: 'post',
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({ ratingExperience, ratingFood, ratingDelivery, comment })
                        }).then(resp => {
                            if (resp.status === 200) {
                                // Update order
                                // submitted
                                updateClosedOrder({ orderId: order.id, attributes: { feedback: { ratingExperience, ratingFood, ratingDelivery, comment } } })
                                setSendingFeedback(false)
                            } else {
                                setSendingFeedback(false)
                            }
                        })
                    }
                }} className="modal-button submit-order clickable" sx={{ background: "#009b4d", color: "white", fontWeight: "600", fontFamily: "inherit", marginTop: "1rem" }}>{t('rating-send')}</LoadingButton>}
            </div>}
        </div>
    </div >
}

function CouponField({ restaurantId, coupon, setCoupon }) {
    const [hid, setHid] = useState(coupon.hid)

    return <div className="coupon-container">
        <TextField id="coupon" placeholder="Coupon ID" defaultValue={hid} variant="outlined" size="small" onChange={(e) => {
            setHid(e.target.value)
            if (coupon.checked) {
                setCoupon({ ...coupon, hid: e.target.value, checked: false })
            }
        }} />
        <div className={"check " + (coupon.checked ? "yes" : "no")}
            onClick={() => {
                if (!coupon.checked) {
                    fetch(`${config.url.API}/coupons-public/${restaurantId}/${hid}`, {
                        method: 'get',
                    }).then(resp => {
                        if (resp.status === 200) {
                            resp.json().then(couponDetails => {
                                setCoupon({ ...couponDetails, checked: true })
                            })
                        } else {
                            setCoupon({ hid: null, checked: false })
                        }
                    })
                }
            }}
        >{(coupon.checked) ? <CheckIcon /> : "Add"}
        </div>
    </div>
}

class OrdersPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            openOrder: JSON.parse(deviceStorage.getItem("openOrder")),
            openOrders: JSON.parse(deviceStorage.getItem("openOrders")) || [],
            closedOrders: JSON.parse(deviceStorage.getItem("closedOrders")) || [],
            payment_option: null,
            payment_suboption: null,
            orderComment: null,
            coupons: [{ hid: null, checked: false }],
            couponDetails: {}, // TODO: remove
            table_number: this.props.table,
            room_number: null, //this.props.room_number,
            location_status: null,
            latitude: null,
            longitude: null,
            placingOrder: false,
            diningMode: "delivery", // TODO: read from metadata
            delivery_service: "Gallery Restaurant" // TODO: Remove default
            // ask_room_number: true // TODO: put in metadata
        };

        this.restaurantId = props.restaurantId
        this.geoFindMe = this.geoFindMe.bind(this)
        this.locationSuccess = this.locationSuccess.bind(this)
        this.locationError = this.locationError.bind(this)
    }

    componentDidMount() {
        getOrdersMetadata({
            restaurantId: this.props.restaurantId, successCallback: ((data) => {
                this.setState({ ...data })
            }).bind(this)
        })

        // TODO: utils
        try {
            const ele = document.getElementsByName("basket")
            if (ele[0]) ele[0].scrollIntoView({ behavior: "smooth", block: "nearest", inline: "center" })
        } catch (e) { }
    }

    locationSuccess(position) {
        this.setState({
            location_status: "success",
            longitude: position.coords.longitude,
            latitude: position.coords.latitude
        })
    }

    locationError() {
        this.setState({
            location_status: "failed",
        })
    }

    geoFindMe() {
        if (!navigator.geolocation) {
            this.setState({
                location_status: "Geolocation is not supported by your browser",
            })
        } else {
            this.setState({
                location_status: "Locating...",
            })
            navigator.geolocation.getCurrentPosition(this.locationSuccess, this.locationError);
        }
    }

    render() {
        const { t } = this.props;

        return <>
            {this.props.toggleOrderView &&
                <div>
                    <div style={{ position: "fixed", width: "100%", zIndex: "1", display: "flex", background: "white", color: "black", alignItems: "center", padding: "1rem", cursor: "pointer" }} onClick={this.props.toggleOrderView}>
                        <ChevronLeftIcon />
                        <b>{t('back-to-menu')}</b>
                    </div>
                </div>}
            <div>
                <div style={{ width: "400px", maxWidth: "90%", margin: "auto", marginTop: "5rem" }}><h2 name="basket">{t('basket')}</h2></div>
                <div className="receipt">
                    <div className="receipt-content">
                        {(this.state.openOrder && this.state.openOrder.items.length) ? <>
                            <div>
                                {this.state.openOrder.items.map((item, i) => {
                                    const nitems = item.quantity || 1

                                    return <>
                                        {[...Array(nitems).keys()].map(q => <div key={`${item.item.id}-${q}-${nitems}`}>
                                            <div className="order-item">
                                                <ClearIcon className="clear-icon clickable" onClick={() => {
                                                    this.setState({ openOrder: updateItemQuantity(i, nitems - 1) })

                                                }} />
                                                <p style={{ marginRight: "1.5rem" }}>
                                                    <div>
                                                        <b>{item.item.name}</b>
                                                    </div>
                                                    <div>
                                                        {(item.price != null && (item.item.price.length >= item.price + 1)) && <p>{t('price')}: {formattedPrice(item.item.price[item.price], this.props.priceFormatter)}</p>} {/* TODO: display price nicely */}
                                                    </div>
                                                </p>
                                                {(item.options?.length > 0) && <>{
                                                    item.options.length === 1 ?
                                                        <p>{t('option')}: {formattedPrice(item.item.options[item.options[0]], this.props.priceFormatter)}</p> :
                                                        <p>{t('options')}: {item.options.map(option => formattedPrice(item.item.options[option], this.props.priceFormatter)).join(' | ')}</p>}
                                                </>}
                                                {(item.customizations?.length > 0) && <>{
                                                    item.customizations.length === 1 ?
                                                        <p>{t('customization')}: {item.customizations.join(',')}</p> :
                                                        <p>{t('customizations')}: {item.customizations.join(',')}</p>}
                                                </>}
                                                {item.comment && <p>{t('comment')}:&#160;{item.comment}</p>}
                                            </div>
                                            <div style={{ width: "100%", borderBottom: "1px solid rgb(240, 240, 240)" }}></div>
                                        </div>
                                        )}
                                    </>
                                })}
                            </div>
                            {(this.state.coupon && ((!this.state.insertCoupon && this.state.payment_option !== "coupon") ? <div onClick={() => this.setState({ insertCoupon: true })} style={{ marginTop: "0.5rem", cursor: "pointer" }}>+ {t('payment-coupon')}</div> :
                                <>
                                    {this.state.coupons.map((coupon, i) => <CouponField
                                        key={`${i}+${this.state.coupons.length}-${coupon.checked}`}
                                        restaurantId={this.props.restaurantId}
                                        coupon={coupon}
                                        setCoupon={coupon => {
                                            const alreadyExists = this.state.coupons.some((existingCoupon, j) => {
                                                if (j === i) return false // We don't check the current one

                                                if (existingCoupon.hid === coupon.hid) return true
                                            })

                                            if (!alreadyExists) {
                                                var newCoupons = [...this.state.coupons]
                                                newCoupons[i] = coupon

                                                if ((i === newCoupons.length - 1) && coupon.checked === true) {
                                                    newCoupons.push({ hid: null, checked: false })
                                                }
                                                this.setState({ coupons: newCoupons })
                                            }
                                        }}
                                    />)}
                                </>
                            ))}
                            <div style={{ margin: "1rem 0" }}>
                                <div style={{ marginBottom: "0.5rem" }}>{t('price')}: {this.props.priceFormatter ? this.props.priceFormatter.format(correctGst(totalAmount(this.state.openOrder.items, this.state.coupons), this.state.a_gst ? 0 : (this.state.gst || 0))) : correctGst(totalAmount(this.state.openOrder.items, this.state.coupons), this.state.a_gst ? 0 : (this.state.gst || 0))}</div>
                                <div style={{ marginBottom: "0.5rem" }}>{t('tax')}: {this.state.gst}%</div>
                                <div style={{ marginBottom: "0.5rem" }}><b>{t('total')}: {this.props.priceFormatter ? this.props.priceFormatter.format(correctGst(totalAmount(this.state.openOrder.items, this.state.coupons), this.state.a_gst ? this.state.gst : 0)) : correctGst(totalAmount(this.state.openOrder.items, this.state.coupons), this.state.a_gst ? this.state.gst : 0)} </b></div>
                                {/* {(!this.state.a_gst && this.state.gst) ? <div style={{ marginTop: "0.5rem" }}>{t("includes-gst")}{this.state.gst}% </div> : <></>} */}
                                {/* {this.state.couponChecked && <div>{t("coupon-applied")}</div>} */}
                            </div>
                            {this.state.ask_id && <div style={{ marginTop: "2rem" }}>
                                {this.state.ask_name && <TextField sx={{ fontFamily: "inherit", fontSize: "inherit", marginTop: "1rem" }} fullWidth label={t('your-name')} size="small" onChange={(e) => this.setState({ name: e.target.value })} />}
                                {this.state.ask_phone && <TextField sx={{ fontFamily: "inherit", fontSize: "inherit", marginTop: "1rem" }} fullWidth id="phone-input" label={t('your-phone')} variant="outlined" size="small" onChange={(e) => this.setState({ phone: e.target.value })} />}
                                {this.state.ask_address && <>
                                    <TextField id="cus-address-line1" style={{ marginTop: "1rem" }} fullWidth placeholder={t('your-delivery')} variant="outlined" size="small" onChange={(e) => this.setState({ cus_address_line: e.target.value })} />
                                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "1rem" }}>
                                        <TextField id="cus-address-zip" placeholder={t('your-zip')} variant="outlined" size="small" onChange={(e) => this.setState({ cus_address_zip: e.target.value })} />
                                        <TextField id="cus-address-city" placeholder={t('your-city')} variant="outlined" size="small" onChange={(e) => this.setState({ cus_address_city: e.target.value })} />
                                    </div>
                                </>}
                                {(this.state.diningMode === "delivery") && <div className="dining-mode">
                                    <FormControl fullWidth size='small' sx={{ marginTop: "1rem" }}>
                                        <InputLabel id="delivery-service-label">{t('delivery-service')}</InputLabel>
                                        <Select
                                            labelId="delivery-service-label"
                                            label={t('delivery-service')}
                                            defaultValue={"Gallery Restaurant"}
                                            onChange={(e) => this.setState({ delivery_service: e.target.value })}>
                                            <MenuItem value={"Gallery Restaurant"}>Gallery Restaurant</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>}
                                {(this.state.ask_delivery_point) && <>
                                    <FormControl fullWidth size='small' sx={{ marginTop: "1rem" }}>
                                        <InputLabel id="delivery-point-label">{t('Delivery point')}</InputLabel>
                                        <Select
                                            labelId="delivery-point-label"
                                            label={t('Delivery point')}
                                            onChange={(e) => this.setState({ deliveryPoint: e.target.value })}>
                                            {this.state.delivery_points?.map( dp => <MenuItem value={dp}>{dp}</MenuItem> )}
                                        </Select>
                                    </FormControl>
                                </>}
                                {this.state.ask_location && <>
                                    <div className={'geo' + (this.state.location_status === "success" ? " success" : "")} onClick={this.geoFindMe}>
                                        {this.state.location_status ? <div>{`Location ${this.state.latitude}° / ${this.state.longitude}°`}</div>
                                            : <div style={{ display: "flex", justifyContent: "center" }}>{t("click-location")}&#160;<ShareLocationIcon /></div>}
                                    </div>
                                </>}
                                {this.state.ask_room && <TextField sx={{ fontFamily: "inherit", fontSize: "inherit", marginTop: "1.5rem" }} id="room-number" fullWidth label={t('room-number')} size="small" onChange={e => this.setState({ room_number: e.target.value })} />}
                            </div>}
                            {((this.state.dine_in_now && this.state.dine_in_now.active) || (this.state.dine_in_later && this.state.dine_in_later.active) || (this.state.pickup && this.state.pickup.active) || (this.state.delivery?.active)) && <>
                                <FormControl fullWidth size='small' sx={{ marginTop: "1rem" }}>
                                    <InputLabel id="order-mode-label">{t('order-mode')}</InputLabel>
                                    <Select
                                        labelId="order-mode-label"
                                        label={t('order-mode')}
                                        onChange={(e) => this.setState({ diningMode: e.target.value, diningTime: null })}>
                                        {(this.state.dine_in_now && this.state.dine_in_now.active) && <MenuItem value="dine-in-now">{t('at-restau')}</MenuItem>}
                                        {(this.state.dine_in_later && this.state.dine_in_later.active) && <MenuItem value="dine-in-later">{t('dine-in-pre-order')}</MenuItem>}
                                        {(this.state.pickup && this.state.pickup.active) && <MenuItem value="pickup">{t('pickup')}</MenuItem>}
                                        {(this.state.delivery?.active) && <MenuItem value="delivery">{t('delivery')}</MenuItem>}
                                    </Select>
                                </FormControl>
                            </>}
                            {(this.state.diningMode === "dine-in-now" && this.state.dine_in_now.table_number) && <div className="dining-mode">
                                <TextField defaultValue={this.props.table} id="table-input" label={t('table')} sx={{ marginTop: "1rem" }} type="number" min="0" variant="outlined" size="small" onChange={(e) => this.setState({ table_number: e.target.value })} />
                            </div>}
                            {(this.state.diningMode === "dine-in-later") && <div className="dining-mode" style={{ marginTop: "1rem" }} >
                                <TimeSelector label={t('possible-dine-in')} callback={((value) => { if (value !== this.state.diningTime) this.setState({ diningTime: value }) }).bind(this)} time_slots={this.state.dine_in_later.time_slots || []} />
                            </div>}
                            {(this.state.diningMode === "pickup") && <div className="dining-mode" style={{ marginTop: "1rem" }} >
                                <TimeSelector label={t('possible-pickup')} callback={((value) => { if (value !== this.state.diningTime) this.setState({ diningTime: value }) }).bind(this)} time_slots={this.state.pickup.time_slots || []} />
                            </div>}
                            <FormControl fullWidth size='small' sx={{ marginTop: "1rem" }}>
                                <InputLabel id="payment-method-label">{t('payment-method')}</InputLabel>
                                <Select
                                    labelId="payment-method-label"
                                    label="Payment method"
                                    onChange={(e) => this.setState({ payment_option: e.target.value, payment_suboption: null })}
                                >
                                    {this.state.payment_room_charge && <MenuItem value="room_charge">{t('payment-room')}</MenuItem>}
                                    {this.state.payment_staff && <MenuItem value="payment_staff">{t('payment-staff')}</MenuItem>}
                                    {this.state.payment_cash_on_delivery && <MenuItem value='cash_on_delivery'>{t('payment-cash')}</MenuItem>}
                                    {this.state.payment_card_on_delivery && <MenuItem value='card_on_delivery'>{t('payment-card')}</MenuItem>}
                                    {this.state.payment_transfer && <MenuItem value='payment_transfer'>{payment_methods['payment_transfer']}</MenuItem>}
                                    {this.state.coupon && <MenuItem value='coupon'>{t('payment-coupon')}</MenuItem>}
                                </Select>
                                {this.state.payment_option === "payment_transfer" && <>
                                    {this.state.payment_transfer_options_new.map((option, i) => {
                                        if (!option.active) return null

                                        return <div
                                            className={"payment-option sub clickable" + (this.state.payment_suboption === option['label'] ? " selected" : "")}
                                            style={{ background: (option.color || '#fff') }}>
                                            <a
                                                href={option['link'] || "#"}
                                                target={option['link'] ? "_blank" : ""}
                                                onClick={() => this.setState({ payment_suboption: option['label'] })}>
                                                {option['label'] && <div style={{ color: (option.color ? contrastingColorBw(option.color) : "black") }}>{option['label']}</div>}
                                                <div className="payment-logo" >
                                                    {option['logo'] ? <div style={{ background: `url("${config.url.PUBLIC}/restaurants/${this.props.restaurantId}/images/${option.logo}") center center / contain no-repeat` }}></div> : (option['link'] && <OpenInNewIcon />)}
                                                </div>
                                            </a>
                                        </div>
                                    })}
                                </>}
                            </FormControl>
                            <div style={{ margin: "1.25rem 0" }}>
                                <div style={{ margin: "1rem 0", display: "flex", justifyContent: "space-between" }}>
                                    <LoadingButton onClick={this.props.toggleOrderView} className="modal-button submit-order clickable" sx={{ background: "rgb(230 27 73)", color: "white", fontWeight: "600", fontFamily: "inherit" }}>{t('cancel')}</LoadingButton>
                                    <LoadingButton
                                        sx={{ background: "#009b4d", color: "white", fontWeight: "600", fontFamily: "inherit" }}
                                        className="modal-button submit-order clickable" loading={this.state.placingOrder}
                                        onClick={() => {
                                            if (!this.state.placingOrder) {
                                                this.setState({ placingOrder: true }, () => {
                                                    var openOrderToSubmit = JSON.parse(JSON.stringify(this.state.openOrder))

                                                    var customerInfo = ''

                                                    if (this.state.ask_id) {
                                                        if (this.state.ask_name && !this.state.name) {
                                                            this.setState({ errMsg: "Name is required", placingOrder: false })
                                                            return
                                                        } else customerInfo += `${this.state.name}`

                                                        if (this.state.ask_phone) {
                                                            if (!this.state.phone) {
                                                                this.setState({ errMsg: "Phone is required", placingOrder: false })
                                                                return
                                                            } else customerInfo += `\nPhone: ${this.state.phone}`
                                                        }

                                                        if (this.state.ask_address) {
                                                            if (!this.state.cus_address_line || !this.state.cus_address_city || !this.state.cus_address_zip) {
                                                                this.setState({ errMsg: "Address incomplete", placingOrder: false })
                                                                return
                                                            } else customerInfo += `\nAddress: ${this.state.cus_address_line}, ${this.state.cus_address_zip} ${this.state.cus_address_city}`
                                                        }

                                                        if (this.state.ask_location) {
                                                            if (!this.state.latitude || !this.state.longitude) {
                                                                this.setState({ errMsg: "Location missing", placingOrder: false })
                                                                return
                                                            } else customerInfo += `\nLocation: Latitude ${this.state.latitude} °, Longitude ${this.state.longitude} °`
                                                        }

                                                        if (this.state.ask_room) {
                                                            if (!this.state.room_number) {
                                                                this.setState({ errMsg: "Room number missing", placingOrder: false })
                                                                return
                                                            } else customerInfo += `\nRoom: ${this.state.room_number}`
                                                        }

                                                        if (this.state.ask_delivery_point) {
                                                            if (!this.state.deliveryPoint) {
                                                                this.setState({ errMsg: "Delivery point missing", placingOrder: false })
                                                                return
                                                            } else customerInfo += `\nDelivery Point: ${this.state.deliveryPoint}`
                                                        }
                                                    }

                                                    openOrderToSubmit['customer_info'] = customerInfo

                                                    if ((this.state.payment_transfer || this.state.payment_cash_on_delivery || this.state.payment_staff || this.state.coupon) && !this.state.payment_option) {
                                                        this.setState({ errMsg: "Payment option required", placingOrder: false })
                                                        return
                                                    }

                                                    var paymentInfo = ''
                                                    if (this.state.payment_option) paymentInfo += `${payment_methods[this.state.payment_option]}`
                                                    if (this.state.payment_suboption) paymentInfo += `\n${this.state.payment_suboption}`
                                                    openOrderToSubmit['payment_method'] = paymentInfo

                                                    openOrderToSubmit['pickup_time'] = this.state.diningTime
                                                    openOrderToSubmit['dining_mode'] = this.state.diningMode
                                                    if (this.state.diningMode === "dine-in-now") openOrderToSubmit['table_number'] = this.state.table_number

                                                    if (this.state.diningMode === "delivery") {
                                                        if (!this.state.delivery_service) {
                                                            this.setState({ errMsg: "Delivery service missing", placingOrder: false })
                                                            return
                                                        }
                                                        // TODO: For now by default will be "Gallery Restaurant" else openOrderToSubmit['delivery_service'] = this.state.delivery_service
                                                    }

                                                    openOrderToSubmit.coupons_hid = (this.state.coupons.filter(coupon => coupon.checked) || []).map(coupon => coupon.hid)

                                                    for (let o = 0; o < this.state.openOrder.items.length; o++) {
                                                        openOrderToSubmit.items[o] = {
                                                            id: this.state.openOrder.items[o].item.id,
                                                            quantity: this.state.openOrder.items[o].quantity,
                                                            labels: this.state.openOrder.items[o].item.labels,
                                                            name: this.state.openOrder.items[o].item.name,
                                                            price: this.state.openOrder.items[o].item.price[this.state.openOrder.items[o].price],
                                                            options: this.state.openOrder.items[o].options.map(optionIndex => this.state.openOrder.items[o].item.options[optionIndex]),
                                                            comment: this.state.openOrder.items[o].comment,
                                                            customizations: this.state.openOrder.items[o].selectedCustomizations
                                                        }
                                                    }

                                                    placeOrder({ order: openOrderToSubmit, restaurantId: this.restaurantId }).then(resp => {
                                                        if (resp.status === 409) {
                                                            resp.json().then(msg => setTimeout(() => this.setState({ errMsg: msg, placingOrder: false }), 1000))
                                                        } else if (resp.status === 200) {
                                                            resp.json().then(respJson => {
                                                                setTimeout(() => {
                                                                    if (respJson.status === 'waiting') {
                                                                        var placedOrder = openOrderToSubmit
                                                                        placedOrder.number = respJson.order_number
                                                                        placedOrder.id = respJson.id
                                                                        placedOrder.status = 'Sent to the kitchen'
                                                                        placedOrder.order_number = respJson.order_number
                                                                        placedOrder.coupons = this.state.coupons
                                                                        var openOrders = [placedOrder, ...this.state.openOrders]
                                                                        deviceStorage.setItem("openOrders", JSON.stringify(openOrders))
                                                                        deviceStorage.setItem("openOrder", null)

                                                                        this.setState({ openOrders, openOrder: null, errMsg: "", placingOrder: false })
                                                                    }
                                                                }, 1000)
                                                            })
                                                        } else {
                                                            alert(t('error-order'))
                                                            setTimeout(() => this.setState({ errMsg: t('error-order'), placingOrder: false }), 1000)
                                                        }
                                                    })
                                                })
                                            }
                                        }}
                                    >
                                        <span>{t('order')}</span>
                                    </LoadingButton>
                                </div>
                                {this.state.errMsg && <div style={{ color: "red", textAlign: "center", fontSize: "80%" }}>{this.state.errMsg}</div>}
                                {/* {this.state.payment_staff && !(this.state.payment_transfer || this.state.payment_cash_on_delivery) && <div className="note">{t('payment-staff-taken')}</div>} */}
                                <div style={{ marginTop: "1rem" }}>
                                    <a style={{ fontSize: "80%" }}
                                        href="https://www.privacypolicygenerator.info/live.php?token=wGGtkHFI3MdKx5HpDWSZYKwI42xkUbse"
                                        target="_blank">{t("privacy-policy")}</a>
                                </div>
                            </div>
                        </>
                            :
                            <div className="note" style={{ textAlign: "center" }}>{t('empty-basket')}</div>
                        }
                    </div>
                </div>
                <div style={{ width: "400px", maxWidth: "90%", margin: "auto" }}><h2 style={{ marginTop: "4rem" }}>{t('orders-in-progress')}</h2></div>
                {
                    this.state.openOrders.length > 0 ?
                        this.state.openOrders.map((order, i) => <Receipt order={order} key={`${order.id}`} autoRefresh={i < 4} gst={this.state.gst} a_gst={this.state.a_gst} priceFormatter={this.props.priceFormatter} qrcode={true} />)
                        :
                        <div className="receipt">
                            <div className="receipt-content">
                                <div className="note" style={{ textAlign: "center" }}>{t('no-order')}</div>
                            </div>
                        </div>
                }
                {
                    (this.state.closedOrders.length > 0) && <>
                        <div style={{ width: "400px", maxWidth: "90%", margin: "auto" }}><h2 style={{ marginTop: "4rem" }}>{t('closed-orders')}</h2></div>
                        {this.state.closedOrders.map((order, i) => <Receipt order={order} key={`r-${order.id}-${i}`} autoRefresh={false} gst={this.state.gst} a_gst={this.state.a_gst} priceFormatter={this.props.priceFormatter} review={true} />)}
                    </>
                }
            </div >
        </>
    }
}

const OrdersPageTranslated = withTranslation('ordering')(OrdersPage)

export default OrdersPageTranslated
