import React, { useState } from 'react';
import './DateTimeUtils.css'
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';

const step = 5
var tt = 0
var times = []

for (var i = 0; tt < 24 * 60; i++) {
    var hh = Math.floor(tt / 60); // getting hours of day in 0-24 format
    var mm = (tt % 60); // getting minutes of the hour in 0-55 format
    times[i] = ("0" + hh).slice(-2) + ':' + ("0" + mm).slice(-2);
    tt = tt + step;
}

export function TaSTimePicker({ callback, minValue, validSlots }) {
    const validValues = times.filter(t => (t >= minValue) && (validSlots.map(slot => t >= slot[0] && t<= slot[1]).some(v => v)))
    const [value, setValue] = useState(validValues.length > 0 ? validValues[0] : "");
    if (value) callback(value)

    return <div className="time-picker">
        <FormControl>
            <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                defaultValue={value}
                value={value}
                onChange={(e) => {
                    const newValue = e.target.value
                    setValue(newValue)
                    callback(newValue)
                }}
                size="small"
            >
                {validValues.map(t => <MenuItem value={t}>{t}</MenuItem>)}
            </Select>
        </FormControl>
    </div>
}